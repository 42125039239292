import React, { useState, useEffect } from "react";
import {
  Grid, LinearProgress
} from "@material-ui/core";
import { CheckBox as CheckIcon,  IndeterminateCheckBox as MinusIcon, 
  CheckBoxOutlineBlank as EmptyIcon, AssignmentTurnedIn as AttendenceIcon, AssignmentLate as AbsenceIcon, ThumbUp as SuccessIcon  } from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import MUIDataTable from "mui-datatables";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {sortBy,dedupArr} from "../../helper";
import NumberCard from "../../components/NumberCard/NumberCard";



export default function Inspections(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const userState = useUserState();
  const user = userState.user;

  // local
  const [myGroups, setMyGroups] = useState([]);
  const [myInspections, setMyInspections] = useState([]);
  const [myCourses, setMyCourses] = useState([]);
  const [myPrograms, setMyPrograms] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({"program_id":"0","group_id":"0","program_date":"0"});

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  const columns = [
      {
        label:t('Group'),
        name: "institue_group"
      },
      {
        label: t('Program'),
        name: "program"
      },
      {
        label: t('Topic'),
        name: "topic"
      },
      {
        label: t('Text'),
        name: "text"
      },
      {
        label: t('Course Date'),
        name: "course_date"
      },
      {
        label:t('Attendence'),
        name: "attendence",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              value>=0 ? (value>0 ? <CheckIcon color="primary" /> : <MinusIcon color="secondary" />) : <EmptyIcon /> 
            );
          }
        }
      }
    ];


  useEffect(() => {
    fetchData();
  }, []);


  async function fetchData(){
    let programs = [];
    let courses = [];
    let inspections = [];

    let groupsRec = await genericFetch(config.apiURL + '/records/institue_group_users?filter=user_id,eq,'+user._id+'&join=institue_groups', {...config.fetchConfig}, 
      null, setIsLoading, setErr, setMsg);

    const ugroups = groupsRec.records.map(group => group.institue_group_id._id).join(','); 

    let coursesRec = await genericFetch(config.apiURL + '/records/courses?filter=institue_group_id,in,'+ugroups+'&join=programs&join=institue_groups&join=texts,topics', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg);
    
    programs = dedupArr(coursesRec.records.sort(sortBy("course_date")).map(course=>course.program_id));
    courses = coursesRec.records.sort(sortBy("course_date"));

    setMyCourses(courses);
    setMyPrograms(programs);    
    setMyGroups(groupsRec.records.map(group => group.institue_group_id))

    ugroups && genericFetch(config.apiURL + '/records/inspections?filter=student_id,eq,'+user._id , {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
    .then(inspectionsRec => {

      inspections = inspectionsRec.records;
      let resultArray = [];
      for (let i = 0; i < courses.length; i++) {
        let flag = -1;
        for (let j = 0; j < inspections.length; j++) {
          if(courses[i]._id === inspections[j].course_id){
            flag = inspections[j].flag;
          }
        }
        resultArray.push([
          courses[i].institue_group_id.group_name,
          courses[i].program_id.program_title,
          courses[i].text_id.topic_id.topic_name,
          courses[i].text_id.title,
          courses[i].course_date,
          flag
        ]);
      }

      setData(resultArray);
      setMyInspections(inspectionsRec.records);
    });

    return null;
  }

  const openInspection = (e, cid,gid) => {
    e.preventDefault();  
    props.history.push("/app/teacher/inspections/check/"+cid+"/"+gid);
  }

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg} />}
      {isLoading && <LinearProgress />}
      <PageTitle title={t("My Attendence")} />
      {!isLoading &&
      <>
        <Grid container spacing={4}>
          <NumberCard 
            icon={<AttendenceIcon style={{ fontSize: 54 }} color="primary"/>}
            header={myInspections.filter(ins => ins.flag>0).length}
            subtitle={t("Total Attendence")}
            />
          <NumberCard 
            icon={<AbsenceIcon style={{ fontSize: 54 }} color="secondary"/>}
            header={myInspections.filter(ins => ins.flag==0).length}
            subtitle={t("Total Absence")}
            />
          <NumberCard 
            icon={<SuccessIcon style={{ fontSize: 54 }} color="primary"/>}
            header={Math.trunc(myInspections.filter(ins => ins.flag>0).length / myInspections.length * 100) + "%"}
            subtitle={t("Attendence Rate")}
            />
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            {data.length>0 && columns.length>0 &&
            <MUIDataTable
              title={t('Attendence List')}
              data={data}
              columns={columns}
              options={{
                filterType: "dropdown",
                selectableRows:"none",
                responsive:"scroll"
              }}/>
            }
          </Grid>
        </Grid>
      </>
      }
    </>
  );
}

