import React from "react";
import {
  Home as HomeIcon,
  Schedule as MyProgramIcon,
  AccountBox as MyProfileIcon,
  Message as MessageIcon,
  Face as StudentAreaIcon,
  Person as TeacherAreaIcon,
  MenuBook as LibraryIcon,
  PersonOutline as AdminIcon,
  SupervisorAccount as IAdminIcon,

  QuestionAnswer as SupportIcon,
  HelpOutline as FAQIcon,
  EventAvailable as InspectionIcon,
  NotificationsNone as NotificationsIcon,
} from "@material-ui/icons";

import Dot from "../Sidebar/components/Dot";

// config
import config from "../../config";


// pages
import Dashboard from "../../pages/dashboard";
import MyProgram from "../../pages/program/myProgram";
import MyProfile from "../../pages/profile/myProfile";
import MyMessages from "../../pages/messages/myMessages";
import Library from "../../pages/lib/library";
import Text from "../../pages/lib/text";
import TCourse from "../../pages/teacherArea/course";
import TAllCourses from "../../pages/teacherArea/allCourses";
import TInspections from "../../pages/teacherArea/inspections";
import TInspectionCheck from "../../pages/teacherArea/inspectionCheck";
import TAssessments from "../../pages/teacherArea/assessments";
import SInspections from "../../pages/studentArea/inspections";
import Notifications from "../../pages/notifications/Notifications";
import AdminUsers from "../../pages/admin/UserManagement";
import AdminUserConfirm from "../../pages/admin/UserConfirm";
import AdminUserEdit from "../../pages/admin/UserEdit";
import AdminInstitues from "../../pages/admin/InstitueManagement";
import AdminInstitueEdit from "../../pages/admin/InstitueEdit";
import AdminGroups from "../../pages/admin/GroupManagement";
import AdminGroupEdit from "../../pages/admin/GroupEdit";
import IAdminUserConfirm from "../../pages/institueAdmin/UserConfirm";
import IAdminUsers from "../../pages/institueAdmin/UserManagement";
import IAdminUserEdit from "../../pages/institueAdmin/UserEdit";
import IAdminGroups from "../../pages/institueAdmin/GroupManagement";
import IAdminGroupEdit from "../../pages/institueAdmin/GroupEdit";
import IAdminTopics from "../../pages/institueAdmin/TopicManagement";
import IAdminTopicEdit from "../../pages/institueAdmin/TopicEdit";
import IAdminTexts from "../../pages/institueAdmin/TextManagement";
import IAdminTextEdit from "../../pages/institueAdmin/TextEdit";
import IAdminPrograms from "../../pages/institueAdmin/ProgramManagement";
import IAdminProgramEdit from "../../pages/institueAdmin/ProgramEdit";
import IAdminCourseEdit from "../../pages/institueAdmin/CourseEdit";
import IAdminAssessments from "../../pages/institueAdmin/AssessmentManagement";
import IAdminAssessmentEdit from "../../pages/institueAdmin/AssessmentEdit";
import IAdminAttendence from "../../pages/institueAdmin/Attendence";
import IAdminNotifications from "../../pages/institueAdmin/Notifications";


// props: sidebar-> sidebar: id: label: link: icon:    
//        route-> path exact to component


/*
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />

*/              

const menuStructure = [
  { 
    sidebar: true, id: 0, label: "Home", link: "/app/dashboard", icon: <HomeIcon />, 
    route:true, exact: false, component: Dashboard 
  },
  { 
    sidebar: true, id: 1, label: "My Program", link: "/app/myprogram", icon: <MyProgramIcon />, 
    route:true, exact: false, component: MyProgram 
  },
  { 
    sidebar: true, id: 2, label: "My Profile", link: "/app/myprofile", icon: <MyProfileIcon />,
    route:true, exact: false, component: MyProfile
  },
  { 
    sidebar: true, id: 3, label: "My Messages", link: "/app/mymessages", icon: <MessageIcon />,
    route:true, exact: false, component: MyMessages
  },
  { 
    sidebar: true, id: 100, label: "Library", link: "/app/library", icon: <LibraryIcon />, 
    route:true, exact: true, to:'/app/library/lib' ,
    subroutes:[
      { id: 102, link:'/app/library/texts/:textId', component: Text},
      { id: 103, link:'/app/library/lib', component: Library},
    ]
  },
  { 
    sidebar: true, id: 300, label: "Student", link: "/app/student", icon: <StudentAreaIcon />, authlevel:config.roles.student,
    route:true, exact: true, to:'/app/student/homeworks' ,
    children: [
      //{ label: "Performance", link: "/app/student/performance" },
      { label: "Attendence", link: "/app/student/attendence" },
      //{ label: "Homeworks", link: "/app/student/homeworks" },
    ],
    subroutes:[
      { id: 301, link:'/app/student/performance', component: SInspections},
      { id: 302, link:'/app/student/attendence', component: SInspections},
      { id: 303, link:'/app/student/homeworks', component: SInspections},
    ]
  },
  { 
    sidebar: true, id: 400, label: "Teacher", link: "/app/teacher", icon: <TeacherAreaIcon />, authlevel:[config.roles.teacher,config.roles.admin,config.roles.institue_admin],
    route:true, exact: true, to:'/app/teacher/courses' ,
    children: [
      { label: "All Courses", link: "/app/teacher/courses" },
      { label: "Attendence", link: "/app/teacher/attendence" },
      { label: "Assessments", link: "/app/teacher/assessments" },
    ],
    subroutes:[
      { id: 401, link:'/app/teacher/courses/:textId/:courseId/:groupId', component: TCourse},
      { id: 402, link:'/app/teacher/courses', component: TAllCourses},
      { id: 403, link:'/app/teacher/attendence/check/:courseId/:groupId', component: TInspectionCheck},
      { id: 404, link:'/app/teacher/attendence', component: TInspections},
      { id: 405, link:'/app/teacher/assessments', component: TAssessments},
    ]
  },
  { 
    sidebar: false, id: 11, label: "Notifications", link: "/app/notification", icon: <LibraryIcon />, 
    route:true, exact: false, component: Notifications 
  },
  { sidebar: true, id: 50, type: "divider" },
  { 
    sidebar: true, id: 800, label: "Admin", link: "/app/admin", icon: <AdminIcon />, authlevel:config.roles.admin,
    route:true, exact: true, to:'/app/admin/users' ,
    children: [
      { label: "Institues", link: "/app/admin/institues" },
      { label: "Groups", link: "/app/admin/groups" },
      { label: "Users", link: "/app/admin/users" }
    ],
    subroutes:[
      { id: 801, link:'/app/admin/users/confirm/:id', component: AdminUserConfirm},
      { id: 801, link:'/app/admin/users/edit/:id', component: AdminUserEdit},
      { id: 802, link:'/app/admin/users', component: AdminUsers},
      { id: 803, link:'/app/admin/institues/edit/:id', component: AdminInstitueEdit},
      { id: 804, link:'/app/admin/institues', component: AdminInstitues},
      { id: 805, link:'/app/admin/groups/edit/:id', component: AdminGroupEdit},
      { id: 806, link:'/app/admin/groups', component: AdminGroups},
    ]
  },
  { 
    sidebar: true, id: 900, label: "Institue Admin", link: "/app/institueAdmin", icon: <IAdminIcon />, authlevel:[config.roles.admin,config.roles.institue_admin],
    route:true, exact: true, to:'/app/institueAdmin/users' ,
    children: [
      { label: "Users", link: "/app/institueAdmin/users" },
      { label: "Groups", link: "/app/institueAdmin/groups" },
      { label: "Topics", link: "/app/institueAdmin/topics" },
      { label: "Texts", link: "/app/institueAdmin/texts" },
      { label: "Programs", link: "/app/institueAdmin/programs" },
      { label: "Assessments", link: "/app/institueAdmin/assessments" },
      { label: "Attendence", link: "/app/institueAdmin/attendence" },
      { label: "Notifications", link: "/app/institueAdmin/notifications" },
    ],
    subroutes:[
      { id: 901, link:'/app/institueAdmin/users/confirm/:id', component: IAdminUserConfirm},
      { id: 902, link:'/app/institueAdmin/users/edit/:id', component: IAdminUserEdit},
      { id: 903, link:'/app/institueAdmin/users', component: IAdminUsers},
      { id: 904, link:'/app/institueAdmin/groups/edit/:id', component: IAdminGroupEdit},
      { id: 905, link:'/app/institueAdmin/groups', component: IAdminGroups},
      { id: 906, link:'/app/institueAdmin/topics/edit/:id', component: IAdminTopicEdit},
      { id: 907, link:'/app/institueAdmin/topics/:tid/edit/:id', component: IAdminTextEdit},
      { id: 908, link:'/app/institueAdmin/topics', component: IAdminTopics},
      { id: 909, link:'/app/institueAdmin/texts/edit/:id', component: IAdminTextEdit},
      { id: 910, link:'/app/institueAdmin/texts', component: IAdminTexts},
      { id: 911, link:'/app/institueAdmin/programs/edit/:id', component: IAdminProgramEdit},
      { id: 912, link:'/app/institueAdmin/programs/:pid/edit/:id', component: IAdminCourseEdit},
      { id: 913, link:'/app/institueAdmin/programs', component: IAdminPrograms},
      { id: 914, link:'/app/institueAdmin/assessments/edit/:id', component: IAdminAssessmentEdit},
      { id: 915, link:'/app/institueAdmin/assessments', component: IAdminAssessments},
      { id: 916, link:'/app/institueAdmin/attendence', component: IAdminAttendence},
      { id: 917, link:'/app/institueAdmin/notifications', component: IAdminNotifications},
    ]
  },
  
  {
    id: 60,
    label: "UI Elements",
    link: "/app/ui",
    icon: <IAdminIcon />,
    children: [
      { label: "Icons", link: "/app/ui/icons" },
      { label: "Charts", link: "/app/ui/charts" },
      { label: "Maps", link: "/app/ui/maps" },
    ],
  },
  { id: 61, type: "divider" },
  { id: 62, type: "title", label: "HELP" },
  { id: 63, label: "Library", link: "", icon: <LibraryIcon /> },
  { id: 64, label: "Support", link: "", icon: <SupportIcon /> },
  { id: 65, label: "FAQ", link: "", icon: <FAQIcon /> },
  { id: 66, type: "divider" },
  { id: 67, type: "title", label: "PROJECTS" },
  {
    id: 68,
    label: "My recent",
    link: "",
    icon: <Dot size="large" color="warning" />,
  },
  {
    id: 69,
    label: "Starred",
    link: "",
    icon: <Dot size="large" color="primary" />,
  },
  {
    id: 70,
    label: "Background",
    link: "",
    icon: <Dot size="large" color="secondary" />,
  },
];


export default menuStructure;