import React, { useState, useEffect } from "react";
import {
  Grid, LinearProgress, Button, TextField, Link, List, ListItem, ListItemText, ListItemIcon, 
  Card, CardContent, CardActions, IconButton, Badge
} from "@material-ui/core";
import { CollectionsBookmark as CollectionsBookmarkIcon, Description as DescriptionIcon,
  YouTube as YouTubeIcon } from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {sortBy} from "../../helper";

export default function MyTexts(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const {user, isAuthenticated} = useUserState();

  //props
  const { id } = props.match.params;

  // local
  const [topics, setTopics] = useState([]);
  const [topicIndex, setTopicIndex] = useState(0);
  const [filter, setFilter] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(){
    let data = await genericFetch(config.apiURL + '/records/topics?join=texts', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(data => setTopics(data.records));

    return data;
  }

  const handleTopicSelect = (e,index) => {
    e.preventDefault();
    setTopicIndex(index);
  };


  const handleFilter = (e) => {
    setFilter(e.target.value);
  }

  const openText = (e, id) => {
    e.preventDefault();  
    props.history.push("/app/library/texts/"+id);
  }

  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }  
      {isLoading && <LinearProgress /> }
      {isAuthenticated &&
      <>
        <PageTitle title={t("Library")} />
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={3}>
              <Widget title={t("Topics")} disableWidgetMenu>
                <div className={classes.root}>
                  <List component="nav">
                    {topics.map((topic) =>
                      <ListItem key={topic._id}
                        button
                        selected={topic._id === topicIndex}
                        onClick={(e) => handleTopicSelect(e,topic._id)}
                        className={[topic._id === topicIndex && classes.linkIconActive].join(' ')}
                      >
                        <ListItemIcon>
                          <CollectionsBookmarkIcon className={[topic._id === topicIndex && classes.linkIconActive].join(' ')}/>
                        </ListItemIcon>
                        <ListItemText primary={t(topic.topic_name)} />
                      </ListItem>
                    )}
                  </List>
                </div>
              </Widget>
          </Grid>


          <Grid item xs={12} sm={12} md={8} lg={9}>
            <Widget title={t("Texts")} disableWidgetMenu>
              <Grid  item xs={12}>
                <TextField name="filter" label={t("Search")} type="text" className={classes.textField}
                  value={filter}
                  onChange={e => handleFilter(e)} />
              </Grid>
              <Grid container spacing={1}>
              { topics.filter(topic=>topic._id === topicIndex).map(topic=> 
                  topic.texts.filter(text=> 
                        text.title.toLowerCase().includes(filter.toLowerCase())||
                        text.tags.toLowerCase().includes(filter.toLowerCase())||
                        text.institue_groups.toLowerCase().includes(filter.toLowerCase()))
                      .map(text=>
                <Grid  item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <Card key={text._id} className={classes.root} variant="outlined">
                    <CardContent>
                      <Typography className={classes.title} gutterBottom color="secondary">
                        {t("Groups") +": "+ text.institue_groups}
                      </Typography>
                      <Typography variant="h5" component="h2" >
                        <Link onClick={e=>openText(e,text._id)}>{text.title}</Link>
                      </Typography>
                      <Typography className={classes.pos} color="info">
                        {text.tags}
                      </Typography>
                      <Typography variant="body2" component="p" >
                        {text.short_desc}
                      </Typography>
                      <Typography variant="body2" component="p" color="secondary">
                        <IconButton color="inherit">
                          <Badge
                            badgeContent={JSON.parse(text.uri).length}
                            color="primary"
                          >
                            <DescriptionIcon classes={{ root: classes.headerIcon }} />
                          </Badge>
                        </IconButton>
                        <IconButton color="inherit">
                          <Badge
                            badgeContent={JSON.parse(text.url).length}
                            color="primary"
                          >
                            <YouTubeIcon classes={{ root: classes.headerIcon }} />
                          </Badge>
                        </IconButton>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button color="primary" onClick={e=>openText(e,text._id)}>{t("Open Course")}</Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      </>
      }
    </>
  );
}

