import React, { useState, useEffect } from "react";
import {
  Grid, LinearProgress, List, ListItem, ListItemText, ListItemIcon, Divider, Collapse,
  Card, CardActionArea, CardMedia, CardActions, Switch, ListItemSecondaryAction, Button,
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
  InputLabel,Select, TextField,
} from "@material-ui/core";
import {  Description as DescriptionIcon, Subject as SubjectIcon, ExpandLess, ExpandMore, 
  YouTube as YouTubeIcon, ArrowRight as SubMenuIcon, AssignmentTurnedIn as AttendenceIcon, Assignment as AssessmentIcon} from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';
import MUIRichTextEditor from "mui-rte";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Toaster from "../../components/Toaster/Toaster";
import CustomDocument from "../../components/Document/Document";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {sortBy} from "../../helper";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";

export default function Notifications(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const userState = useUserState();
  const user = userState.user;


  // local

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);



  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }  
      {isLoading ? <LinearProgress /> :
      <>
        
      </>
      }
    </>
  );
}

