import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import Breadcrumb from "../Breadcrumb";

// menuConfig
import menuConfig from "./menuConfig";

// context
import { useLayoutState} from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();


  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar structure={menuConfig}/>
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Breadcrumb structure={menuConfig}/>
            <Switch>
              {menuConfig.filter(mitem => ((mitem.route))).map(mitem => (
                (mitem.exact) ? 
                [
                  <Route key={mitem.id} exact path={mitem.link} render={() => <Redirect to={mitem.to} />} />,
                  mitem.subroutes.map(subroute => (
                    <Route key={subroute.id} path={subroute.link} component={subroute.component} />
                  ))
                ]
                : <Route key={mitem.id} path={mitem.link} component={mitem.component} />
              ))}
              
            </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
