import React, { useEffect, useState } from "react";
import {
  Grid, TextField, CircularProgress, Button, Chip, LinearProgress
} from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab';
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {dedup} from "../../helper";


export default function TopicEdit(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  var userState = useUserState();
  const user = userState.user;

  // local
  const [topicData, setTopicData] = useState({"user_id":"","topic_name":"","topic_desc":"","tags":""});
  const [tags, setTags] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  async function fetchData(){
    const { id } = props.match.params;

    let responses = await Promise.all([
      genericFetch(config.apiURL + '/records/topics?include=topics.tags', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data => {
          let allTags = [];
          data.records.forEach(element => {
            element.tags.split(",").forEach(tag => {
              allTags.push(tag)
            })
          });
          setTags(dedup(allTags));
        }),
      id>0 && genericFetch(config.apiURL + '/records/topics/'+id, {...config.fetchConfig }, null, setIsLoading, setErr, setMsg)
        .then(data => setTopicData(data))
    ]);
    return responses;
  }

  async function saveData(){   
    let url,fetchConfig;
    if(topicData._id === undefined){
      url = '/records/topics';
      fetchConfig = config.saveConfig;
    }else{
      url = '/records/topics/'+topicData._id; 
      fetchConfig = config.updateConfig;   
    }
    let response = await genericFetch(config.apiURL + url, {...fetchConfig, body:JSON.stringify(topicData) }, t("Topic successfully saved!"), setIsLoading, setErr, setMsg);

    return response;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();  
    saveData();
  }

  const handleChange = (e) => {
    let obj = { ...topicData, [e.target.name]:e.target.value, "user_id": user._id};
    setTopicData(obj);
  }
  const handleTagsChange = (event, values) => {
    let obj = { ...topicData, "tags":values.join(","), "user_id": user._id};
    setTopicData(obj);
  }

  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }


  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }  
      {isLoading && <LinearProgress />}
      <PageTitle title={t("Topic Management")} />  
      <Grid item xs={9} md={9}>
        <Widget title={t("Topic Info" )} disableWidgetMenu>
          <div className={classes.formContainer}>
            <div className={classes.form}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <>
                <form className={classes.root} noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
                  
                  <div>
                    <TextField name="topic_name" label={t("Topic Name")} type="text" className={classes.textField} 
                                value={topicData.topic_name}
                                onChange={e => handleChange(e)}/>
                  </div>
                  
                  <div>
                    <TextField name="topic_desc" label={t("Description")} type="text" className={classes.textField} 
                              value={topicData.topic_desc}
                              onChange={e => handleChange(e)}/>
                  </div>

                  <div>
                    <Autocomplete
                      multiple
                      id="tags"
                      options={tags}
                      value={topicData.tags.split(',').filter(function(el) {return el.length !== 0})}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} name="tags-input" label={t("Tags")} placeholder={t("Hit enter to save tag")} />
                      )}
                      onChange={handleTagsChange}
                    />
                  </div>

                  <div className={classes.formButtons}>
                    <Button
                      disabled={
                        (topicData &&  topicData.topic_name.length === 0) ? true : false
                      }
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("Save")}
                    </Button>
                    <Button
                      color="secondary"
                      size="large"
                      className={classes.cancelButton}
                      onClick={e => goBack(e)}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </>
            )}
            </div>
          </div>
        </Widget>
      </Grid>
    </>
  );
}
