import React, { useState, useEffect } from "react";
import {
  Grid, LinearProgress, Avatar, ButtonBase, Tab, Tabs, Divider, Button, TextField,  IconButton, 
} from "@material-ui/core";
import { AccountCircle as AccountCircleIcon, AlternateEmail as EmailIcon,  Phone as PhoneIcon,
  Cake as CakeIcon, Home as AddressIcon, GroupWork as GroupIcon, Visibility, VisibilityOff} from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import TabPanel from "../../components/TabPanel/TabPanel";
import { Typography } from "../../components/Wrappers/Wrappers";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {UUID,a11yProps} from "../../helper";


export default function MyCourses(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const userState = useUserState();
  const user = userState.user;

  // local
  const [myData, setMyData] = useState({"password":"","role_id":{"role_name":""},"institue_group_users":[]});
  const [tabIndex, setTabIndex] = React.useState(0);
  const [avatar, setAvatar] = useState({});
  const [password, setPassword] = useState({"pass1":"","pass2":"","showPassword":false,"pass1Err":false,"pass2Err":false,"pass1ErrMsg":"","pass2ErrMsg":"","valid":false});

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(){

    let data = await genericFetch(config.apiURL + '/records/users/'+user._id+'?join=institue_group_users,institue_groups&join=roles', {...config.fetchConfig}, 
      null, setIsLoading, setErr, setMsg);
    setMyData(data)


    return data;
  }

  async function updatePassword(){   
    let url = '/records/users/'+myData._id; 
    
    let response = await genericFetch(config.apiURL + url, {...config.updateConfig, body:JSON.stringify({"password":myData.password })}, t('Password successfully changed!'), setIsLoading, setErr, setMsg);

    return response;
  }

  async function updateAvatar(imageUrl){   
    let url = '/records/users/'+myData._id; 
    
    let response = await genericFetch(config.apiURL + url, {...config.updateConfig, body:JSON.stringify({"avatar_url":imageUrl })}, t('Image successfully changed!'), setIsLoading, setErr, setMsg);

    return response;
  }

  async function uploadAvatar(e){  
    e.preventDefault(); 
    const formData  = new FormData();
    formData.append('file_base64', avatar.data);
    formData.append('filename', avatar.uid);

    let response = 
      await genericFetch(config.imageapiURL + avatar.uid, {...config.fileSaveConfig, body: formData}, null, setIsLoading, setErr, setMsg);

    let updateResponse = await updateAvatar(avatar.uid);
    setAvatar({});
    return updateResponse;
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };


  const handleSubmit = (e) => {
    e.preventDefault();  
    updatePassword();
  }

  const handleImage = ({ target }) => {
    if(target.files[0]){
      const file = target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
          const name = file.name;
          const type = name.split(".").pop();
          const uid = UUID() + "." +type;
          setAvatar({"uid":uid,"data":e.target.result});
          setMyData({...myData,"avatar_url":uid});   
      }
    };
  };

  const handlePassChange = (e) => {
    let tmpPass = { ...password, [e.target.name]:e.target.value};
    tmpPass.valid=false;
    tmpPass.pass1Err=false;
    tmpPass.pass2Err=false;
    tmpPass.pass1ErrMsg="";
    tmpPass.pass2ErrMsg="";

    if(tmpPass.pass1.length > 0 && tmpPass.pass1.length < 5){
      tmpPass.pass1Err=true; tmpPass.pass1ErrMsg = t("Password length should be at least 5 characters");
    }
    if(tmpPass.pass2.length > 0  && tmpPass.pass1 !== tmpPass.pass2){
      tmpPass.pass2Err=true; tmpPass.pass2ErrMsg = t("Passwords are not matching");
    }
    if(tmpPass.pass1.length > 0 && tmpPass.pass2.length > 0 && !tmpPass.pass1Err && !tmpPass.pass2Err) 
      tmpPass.valid=true;

    setPassword(tmpPass);
    (tmpPass.valid) && setMyData({...myData,"password":password.pass1});
  }

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg} />}
      {isLoading && <LinearProgress />}
      <PageTitle title={t("My Profile")} />
      {!isLoading &&
        <Grid container spacing={1}>
          <Grid item xs={12} sm={10} md={10} lg={8} xl={6}>
            <Widget disableWidgetMenu>
              <Grid container spacing={2} direction="column" alignItems="center">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleImage}
                />
                <label htmlFor="raised-button-file">
                  <ButtonBase className={classes.image} component="span">
                    { avatar.data ? <Avatar key={1} alt={myData.name} src={avatar.data}  className={classes.bigAvatar}/>
                    : 
                      [ myData.avatar_url ? <Avatar key={2} alt={myData.name} src={config.imageapiURL + myData.avatar_url}  className={classes.bigAvatar}/>
                      :
                        <Avatar key={3} className={classes.bigAvatar}>
                          <AccountCircleIcon className={classes.bigAvatar}/>
                        </Avatar>
                      ]}
                  </ButtonBase>
                </label> 
                  {avatar.data && 
                    <Button
                      onClick={e => uploadAvatar(e)}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("Save")}
                    </Button>
                  }
                <Typography variant="h4">
                  {myData.name+" "+myData.surname}
                </Typography>
                <Typography variant="h6" color="secondary">
                  {t(myData.role_id.role_name)} 
                </Typography>
              </Grid>
              <Grid item xs>
                <Divider className={classes.divider}/>
                <Tabs 
                  value={tabIndex} 
                  onChange={handleTabChange} 
                  indicatorColor="primary"
                  textColor="secondary"
                  variant="fullWidth">
                    <Tab label={t("My Info")} {...a11yProps(0)} />
                    <Tab label={t("My Groups")} {...a11yProps(1)} />
                    <Tab label={t("Password Change")} {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={tabIndex} index={0}> 
                  <Typography>
                    <EmailIcon/>{myData.email} 
                  </Typography>
                  <Typography >
                    <PhoneIcon/>{myData.phone} 
                  </Typography>
                  <Typography >
                    <CakeIcon/>{myData.birthdate} 
                  </Typography>
                  <Typography >
                    <AddressIcon/>{myData.address} 
                  </Typography>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  { myData.institue_group_users.map((grp,index)=>
                    <Typography key={index}>
                      <GroupIcon/>{grp.institue_group_id.group_name} 
                    </Typography>
                  )}
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <div className={classes.formContainer}>
                    <div className={classes.form}>
                      <form className={classes.root} noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>    
                        <div>
                          <TextField 
                            label={t("Password")}
                            name="pass1"
                            type={password.showPassword ? 'text' : 'password'}
                            value={password.pass1}
                            onChange={e => handlePassChange(e)}
                            className={classes.textField} 
                            error={password.pass1Err}
                            helperText={password.pass1ErrMsg}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {password.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              ),
                            }}
                          />
                        </div>   
                        <div>
                          <TextField 
                            label={t("Retype Password")}
                            name="pass2"
                            type={password.showPassword ? 'text' : 'password'}
                            value={password.pass2}
                            onChange={e => handlePassChange(e)}
                            className={classes.textField} 
                            error={password.pass2Err}
                            helperText={password.pass2ErrMsg}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {password.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              ),
                            }}
                          />
                        </div>
                        <Button
                          disabled={
                            (password.valid) ? false : true
                          }
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          {t("Save")}
                        </Button>
                      </form>
                    </div>
                  </div>
                </TabPanel>
              </Grid>
            </Widget>
            </Grid>
        </Grid>
      }   
    </>
  );
}

