import React from 'react'
import ReactPlayer from 'react-player'
//import { useTheme } from "@material-ui/styles";

//import { useTranslation } from 'react-i18next';


// styles
import useStyles from "./styles";


export default function VideoPlayer(props) {
  var classes = useStyles();
  //var theme = useTheme();

  const {url} = props;
    

  return (
    <div className={classes.playerWrapper}>
        <ReactPlayer
            className={classes.reactPlayer}
            url={url}
            width='100%'
            height='100%'
            controls={true}
        />
    </div>
  );
}
