import React, { useEffect, useState } from "react";
import {
  Grid,  CircularProgress, IconButton, Tooltip
} from "@material-ui/core";
import { Edit as EditIcon, PostAdd as PostAddIcon, Delete as DeleteIcon } from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import MUIDataTable from "mui-datatables";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";


export default function TopicManagement(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // local
  const [datatableData, setDatatableData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  const columns = [
    {
      label: t('Topic'),
      name: "topic_name"
    },
    {
      label:t('Description'),
      name: "topic_desc"
    },
    {
      label: t('User'),
      name: "user_name"
    },
    {
      name: "",
      options: {
        filter:false,sort:false,print:false,download:false,viewColumns:false,
        customBodyRender: (value, tableMeta, updateValue) => (
          [<Tooltip key={1} title={t("New Text")}>
            <IconButton onClick={e => addText(e,value)} color="primary">
              <PostAddIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip key={2} title={t("Edit")}>
            <IconButton onClick={e => editTopic(e,value)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip key={3} title={t("Delete")}>
            <IconButton onClick={e => deleteTopic(e,value)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Tooltip>]
        )
      }
    }
  ];

  async function fetchData(){

    let response = await genericFetch(config.apiURL + '/records/topics?join=users&include=topics.*,users.name,users.surname', {...config.fetchConfig}, 
      null, setIsLoading, setErr, setMsg);

    let resultArray = [];
    for (let i = 0; i < response.records.length; i++) {
      resultArray.push([
        response.records[i].topic_name,
        response.records[i].topic_desc,
        response.records[i].user_id.name + " " + response.records[i].user_id.surname ,
        response.records[i]._id
      ]);
    }
    setDatatableData(resultArray);    

    return response;
  }

  async function deleteData(_id){   
    let url = '/records/topics/'+ _id;    
    let response = await genericFetch(config.apiURL + url, {...config.deleteConfig}, 
      t("Topic successfully deleted!"), setIsLoading, setErr, setMsg);
    return response;
  }

  const editTopic = (e, _id) => {
    e.preventDefault();
    props.history.push("/app/institueAdmin/topics/edit/"+ _id);
  }

  const addText = (e, _id) => {
    e.preventDefault();
    props.history.push("/app/institueAdmin/topics/"+_id+"/edit/-1");
  }

  const deleteTopic = (e, _id) => {
    e.preventDefault();
    if (window.confirm(t('Are you sure you wish to delete this?'))){
      deleteData(_id)    
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }  
      <PageTitle title={t('Topic Management')} button={{title:t('New Topic'), link:"#/app/institueAdmin/topics/edit/-1"}} />
      <Grid item xs={12}>
        {isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : (
          <MUIDataTable
            title={t('Topic List')}
            data={datatableData}
            columns={columns}
            options={{
              filterType: "dropdown",
              selectableRows:"none",
              responsive:"scroll"
            }}
          />
          )}
      </Grid>
    </>
  );
}