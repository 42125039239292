import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  text: {
    color: "white",
  },
  breadcrumb:{
    marginTop: theme.spacing(2) 
  }
}));
