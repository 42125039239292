import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  logotypeContainer: {
    //backgroundColor: theme.palette.secondary.light,
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  logotypeImage: {
    width: "60%",
    marginBottom: theme.spacing(2),
  },
  logotypeText: {
    color: theme.palette.secondary.dark,
    fontWeight: 500,
    fontSize: 84,
    //[theme.breakpoints.down("md")]: {
    //  fontSize: 48,
    //},
  },
}));
