import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
    marginBottom:theme.spacing(2),
    width: "100%"
  },
  largeTextField: {
    borderBottomColor: theme.palette.background.light,
    marginBottom:theme.spacing(2),
    width: "100%",
    height:"50"
  },
  selectField: {
    marginBottom:theme.spacing(2),
    marginRight:theme.spacing(2),
    width: "100%",
  },
  formColumnTexts:{
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textFieldColumn: {
    borderBottomColor: theme.palette.background.light,
    marginRight:theme.spacing(2),
    width: "100%"
  },
  bigAvatar: {
    width: 150,
    height: 150,
  },
  image: {
    margin: theme.spacing(2),
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  chatHeader:{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listItemLeft:{
    display: "flex",
    justifyContent: "flex-start",
  },
  listItemRight:{
    display: "flex",
    justifyContent: "flex-end",
    textAlign: 'right',
  },
  avatarRight:{
    justifyContent: "flex-end",
    display: "flex",
  }
}));
