import React, {useEffect} from "react";
import { Close as CloseIcon,} from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
//import { useTheme } from "@material-ui/styles";


import Notification from "../../components/Notification";

import { useUserDispatch, sessionExpired, signOut } from "../../context/UserContext";

// styles
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";


export default function Toaster(props) {
  var classes = useStyles();
  //var theme = useTheme();
  //var { t } = useTranslation();

  const userDispatch = useUserDispatch();

  useEffect(() => {

    const {err, msg} = props;
    console.log(err) 
    console.log(msg) 
    const options = {
        position: toast.POSITION.TOP_CENTER,
        progressClassName: classes.progress,
        className: classes.notification,
    };
    if(err){
        let otherProps={};
        if(err.code=="1011") {
          otherProps.extraButton = "Login";
          otherProps.extraButtonClick = ()=>signOut(userDispatch)
          sessionExpired(userDispatch);
        }

        const componentProps = {
          type: "error",
          message: err.message,
          variant: "contained",
          color: "secondary",
          ...otherProps
        };
        toast(
        <Notification
            {...componentProps}
            className={classes.notificationComponent}
        />, {type: toast.TYPE.ERROR, ...options});  
        
    }
    if(msg){
        const componentProps = {
        type: "success",
        message: msg,
        variant: "contained",
        color: "success",
        }
        toast(
        <Notification
            {...componentProps}
            className={classes.notificationComponent}
        />, {type: toast.TYPE.SUCCESS, ...options});
    }

    return () =>{

    }
  }, [])
    

  return (
    <ToastContainer
      className={classes.toastsContainer}
      closeButton={
        <CloseButton className={classes.notificationCloseButton} />
      }
      closeOnClick={false}
      progressClassName={classes.notificationProgress}
    />
  );
}

// #############################################################
function CloseButton({ closeToast, className }) {
    return <CloseIcon className={className} onClick={closeToast} />;
  }