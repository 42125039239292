const notJsonId = (a, b, akey, bkey) => {
  var result = [];
  a.forEach(elementa => {
    var found = 0;
    b.forEach(elementb => {
      if(elementa[akey] === elementb[bkey]) found = 1;
    })
    if(found === 0) result.push(elementa)
  });
  return result;
}

const intersectionJsonId = (a, b, akey, bkey) => {
  var result = [];
  a.forEach(elementa => {
    b.forEach(elementb => {
      if(elementa[akey] === elementb[bkey]) result.push(elementa)
    })
  });
  return result;
}

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
}

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const union = (a, b) => {
  return [...a, ...not(b, a)];
}

const dedup = (arr) => {
  let unique = {};
  arr.forEach(function(i) {
    if(!unique[i]) {
      unique[i] = true;
    }
  });
  return Object.keys(unique);
}

const dedupArr = (arr) => {
  const uniqueArray = arr.filter((thing, index) => {
    const _thing = JSON.stringify(thing);
    return index === arr.findIndex(obj => {
      return JSON.stringify(obj) === _thing;
    });
  });
  return uniqueArray;
}

function sortBy(field) {
  return function(a, b) {
    return (a[field] > b[field]) - (a[field] < b[field])
  };
}

const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function UUID(){
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export { UUID, notJsonId, intersectionJsonId, not, intersection, union, dedup,dedupArr, sortBy, a11yProps, groupBy};