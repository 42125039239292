import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from 'react-i18next';

import translationTr from "./locales/tr/translation.json";
import translationEng from "./locales/en/translation.json";
import translationGer from "./locales/de/translation.json";


i18n
  .use(XHR)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    debug: false,
    lng: "tr",
    fallbackLng: "tr", // use en if detected lng is not available

    //keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: { 
      useSuspense: false //   <---- this will do the magic
    },
    langs :[
      { id:"tr", flag:"", name:"Türkçe"  },
      { id:"en", flag:"", name:"English" },
      { id:"de", flag:"", name:"Deutsch" }
    ],
    resources: {
      en: {
        translations: translationEng
      },
      de: {
        translations: translationGer
      },
      tr: {
        translations: translationTr
      }
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations" 
  });

export default i18n;