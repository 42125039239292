import React, { useState, useEffect } from "react";
import {
  Grid, LinearProgress, Select, IconButton, Avatar, Divider
} from "@material-ui/core";
import { CheckBox as CheckIcon,  IndeterminateCheckBox as MinusIcon, 
  CheckBoxOutlineBlank as EmptyIcon, AssignmentTurnedIn as AttendenceIcon, AssignmentLate as AbsenceIcon, ThumbUp as SuccessIcon, 
  FilterList as FilterListIcon, AccountCircle as AccountCircleIcon } from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import MUIDataTable from "mui-datatables";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {sortBy,dedupArr} from "../../helper";
import NumberCard from "../../components/NumberCard/NumberCard";



export default function Attendence(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const userState = useUserState();
  const user = userState.user;

  // local
  const [myGroups, setMyGroups] = useState([]);
  const [myInspections, setMyInspections] = useState([]);
  const [myCourses, setMyCourses] = useState([]);
  const [myPrograms, setMyPrograms] = useState([]);
  const [grpStudents, setGrpStudents] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({"student_id":"0","group_id":"0"});

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  const columns = [
      {
        label:t('Group'),
        name: "institue_group"
      },
      {
        label: t('Program'),
        name: "program"
      },
      {
        label: t('Topic'),
        name: "topic"
      },
      {
        label: t('Text'),
        name: "text"
      },
      {
        label: t('Course Date'),
        name: "course_date"
      },
      {
        label:t('Attendence'),
        name: "attendence",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              value>=0 ? (value>0 ? <CheckIcon color="primary" /> : <MinusIcon color="secondary" />) : <EmptyIcon /> 
            );
          }
        }
      }
    ];


  useEffect(() => {
    fetchData();
  }, []);


  async function fetchData(){

    let coursesRec = await genericFetch(config.apiURL + '/records/courses?filter=institue_id,in,'+user.institue_id._id+'&join=programs&join=institue_groups&join=texts,topics', 
      {...config.fetchConfig}, null, setIsLoading, setErr, setMsg);
    
    setMyCourses(coursesRec.records.sort(sortBy("course_date")));
    setMyPrograms(dedupArr(coursesRec.records.sort(sortBy("course_date")).map(course=>course.program_id)));    
    const groups = dedupArr(coursesRec.records.map(group => group.institue_group_id));
    setMyGroups(groups);


    let groupUsersRec = await genericFetch(config.apiURL + '/records/institue_group_users?filter=institue_group_id,in,'+groups.map(group=>group._id)+'&join=users', 
      {...config.fetchConfig}, null, setIsLoading, setErr, setMsg);
    setGrpStudents(groupUsersRec.records.filter(groupUser => groupUser.user_id.role_id === config.roles.student).map(group => group))

    return null;
  }

  async function getStudentData(student_id){

    genericFetch(config.apiURL + '/records/inspections?filter=student_id,in,'+student_id , 
        {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
    .then(inspectionsRec => {

      let inspections = inspectionsRec.records;
      let courses = myCourses;
      let resultArray = [];
      for (let i = 0; i < courses.length; i++) {
        let flag = -1;
        for (let j = 0; j < inspections.length; j++) {
          if(courses[i]._id === inspections[j].course_id){
            flag = inspections[j].flag;
          }
        }
        if(courses[i].institue_group_id._id == filter.group_id){
          resultArray.push([
            courses[i].institue_group_id.group_name,
            courses[i].program_id.program_title,
            courses[i].text_id.topic_id.topic_name,
            courses[i].text_id.title,
            courses[i].course_date,
            flag
          ]);
        }
      }


      setData(resultArray);
      setMyInspections(inspectionsRec.records);
    });
  }

  const openInspection = (e, cid,gid) => {
    e.preventDefault();  
    props.history.push("/app/teacher/inspections/check/"+cid+"/"+gid);
  }


  const handleFilter = (e) => {
    const obj = { ...filter, [e.target.name]:e.target.value};
    setFilter(obj);
    (e.target.name=="student_id") && getStudentData(e.target.value);
  }

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg} />}
      {isLoading && <LinearProgress />}
      <PageTitle title={t("All Groups Attendence")} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget header>
            <div className={classes.formColumnTexts}>
              <Select
                native
                className={classes.selectField}
                value={filter.group_id}
                onChange={e => handleFilter(e)}
                inputProps={{
                  name: 'group_id',
                  id: 'group-select',
                }}
              >
                <option key={'group_option__0'} value="0" >{t("All Groups")}</option>
                {myGroups.map(group => (
                  <option key={'group_option_' + group._id} value={group._id}>{group.group_name}</option>
                ))}
              </Select>
              <Select
                native
                className={classes.selectField}
                value={filter.student_id}
                onChange={e => handleFilter(e)}
                inputProps={{
                  name: 'student_id',
                  id: 'student-select',
                }}
              >
                <option key={'student_option_0'} value="0" >{t("All Students")}</option>
                {grpStudents
                  .filter(grp => grp.institue_group_id == filter.group_id)
                  .map(grpStudent => grpStudent.user_id)
                  .map(student =>
                    <option key={'student_option_' + student._id} value={student._id}>{student.name +" "+student.surname}</option>
                )}
              </Select>
              <FilterListIcon/>
            </div>
          </Widget>
        </Grid>
      </Grid>
        
      {!isLoading &&
      <>
        {grpStudents
          .map(grpStudent => grpStudent.user_id)
          .filter(student=>student._id == filter.student_id)
          .map((student,index)=>
        <>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
            <Widget disableWidgetMenu>
              <Grid container spacing={2} direction="column" alignItems="center">
                  <IconButton component="span">
                    { student.avatar_url 
                      ? <Avatar key={2} alt={student.name} src={config.imageapiURL + student.avatar_url}  className={classes.bigAvatar}/>
                      : <Avatar key={3} className={classes.bigAvatar}>
                          <AccountCircleIcon className={classes.bigAvatar}/>
                        </Avatar>
                    }
                  </IconButton>
                <Typography component="h5" variant="h5">
                  {student.name+" "+student.surname}
                </Typography>
                <Typography variant="subtitle1" color="secondary">
                  {student.email}
                </Typography>
              </Grid>
              <Divider className={classes.dividerHorizontal}/>
              <Grid container spacing={4}>
                <NumberCard 
                  icon={<AttendenceIcon style={{ fontSize: 54 }} color="primary"/>}
                  header={myInspections.filter(ins => ins.flag>0).length}
                  subtitle={t("Total Attendence")}
                  />
                <NumberCard 
                  icon={<AbsenceIcon style={{ fontSize: 54 }} color="secondary"/>}
                  header={myInspections.filter(ins => ins.flag==0).length}
                  subtitle={t("Total Absence")}
                  />
                <NumberCard 
                  icon={<SuccessIcon style={{ fontSize: 54 }} color="primary"/>}
                  header={Math.trunc(myInspections.filter(ins => ins.flag>0).length / myInspections.length * 100) + "%"}
                  subtitle={t("Attendence Rate")}
                  />
              </Grid>


              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {data.length>0 && columns.length>0 &&
                  <MUIDataTable
                    title={t('Attendence List')}
                    data={data}
                    columns={columns}
                    options={{
                      filterType: "dropdown",
                      selectableRows:"none",
                      responsive:"scroll"
                    }}/>
                  }
                </Grid>
              </Grid>
            </Widget>
          </Grid>
        </Grid>
        </>
        )}
      </>
      }
    </>
  );
}

