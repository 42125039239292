import React, { useState } from "react";
import {
  Grid, LinearProgress
} from "@material-ui/core";

import {
  Phone as PhoneIcon,Favorite as FavoriteIcon,Person as PersonPinIcon,Help as HelpIcon,ShoppingBasket,ThumbDown,ThumbUp 
}from '@material-ui/icons';

//import { useTheme } from "@material-ui/styles";
import Toaster from "../../components/Toaster/Toaster";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";

// logo
import logo from "../../images/logo.svg";

// context
import { useUserState } from "../../context/UserContext";
import { useTranslation } from 'react-i18next';

// config
import config from "../../config";



export default function Dashboard(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const {user} = useUserState();

  // local
  const [myCourses, setMyCourses] = useState({"program_title":"","topic_name":"","title":"","course_date":"","course_time":""});
  const [myMessages, setMyMessages] = useState({"program_title":"","topic_name":"","title":"","course_date":"","course_time":""});
  const [myNotifications, setMyNotifications] = useState({"program_title":"","topic_name":"","title":"","course_date":"","course_time":""});

  // error
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);



  async function fetchData(){

    let [myGroupsRec, messagesRec, notificationsRec, coursesRec] = await Promise.all([
      fetch(config.apiURL + '/records/messages', {...config.fetchConfig }).then(res => res.json()),
      fetch(config.apiURL + '/records/courses?join=institue_groups&join=texts,topics&join=users', {...config.fetchConfig }).then(res => res.json())
    ]);
  }

  


  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg} />}
      {isLoading && <LinearProgress />}

      <Grid container spacing={1}>
        <Grid item xs={12} >
          <Widget disableWidgetMenu>
            <Grid container spacing={2} direction="column" alignItems="center">
              

              <div className={classes.logotypeContainer}>
                <img src={logo} alt="logo" className={classes.logotypeImage} />
                <Typography className={classes.logotypeText}>{config.appName}</Typography>
              </div>

              <Typography variant="h4">
                {user.name + " " + user.surname}
              </Typography>
              <Typography variant="h6" color="secondary">
                {t(user.role_id.role_name)}
              </Typography>
            </Grid>
          </Widget>
        </Grid>
      </Grid>

    </>
  );
}

