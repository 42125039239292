import React, { useEffect, useState } from "react";
import {
  Grid, CircularProgress, IconButton, Tooltip
} from "@material-ui/core";
import { Edit as EditIcon, FileCopy as CopyIcon, Delete as DeleteIcon } from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Toaster from "../../components/Toaster/Toaster";
import MUIDataTable from "mui-datatables";

// config
import config from "../../config";

// context
import { genericFetch } from "../../context/FetchContext";


export default function GroupManagement(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // local
  const [datatableData, setDatatableData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);

  const columns = [
    {
      label: t('Institue'),
      name: "institue"
    },
    {
      label: t('Group Name'),
      name: "name"
    },
    {
      label:t('Description'),
      name: "desc"
    },
    {
      name: "",
      options: {
        filter:false,sort:false,print:false,download:false,viewColumns:false,
        customBodyRender: (value, tableMeta, updateValue) => (
          [<Tooltip key={1} title={t("Edit")}>
            <IconButton onClick={e => editGroup(e,value)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip key={2} title={t("Delete")}>
            <IconButton onClick={e => deleteGroup(e,value)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Tooltip>]
        )
      }
    }
  ];


  async function fetchData(){

    let response = await genericFetch(config.apiURL + '/records/institue_groups?join=institues', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg);
    
    let resultArray = [];
    
    for (let i = 0; i < response.records.length; i++) {
      resultArray.push([
        response.records[i].institue_id.institue_name,
        response.records[i].group_name,
        response.records[i].group_desc,
        response.records[i]._id,
      ]);
    }

    setDatatableData(resultArray);      
  }

  async function deleteData(_id){  
    let url = '/records/institue_groups/'+ _id;    
    let response = await genericFetch(config.apiURL + url, {...config.deleteConfig}, t("Group successfully deleted!"), setIsLoading, setErr, setMsg) ;
    return response;
  }


  const editGroup = (e, _id) => {
    e.preventDefault();
    props.history.push("/app/admin/groups/edit/"+ _id);
  }

  const deleteGroup = (e, _id) => {
    e.preventDefault();
    if (window.confirm(t('Are you sure you wish to delete this?'))){
      deleteData(_id)
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }   
      <PageTitle title={t('Group Management')} button={{title:t('New Group'), link:"#/app/admin/groups/edit/-1"}} />
      <Grid item xs={12}>
        {isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : (
          <MUIDataTable
            title={t('Group List')}
            data={datatableData}
            columns={columns}
            options={{
              filterType: "dropdown",
              selectableRows:"none",
              responsive:"scroll"
            }}
          />
          )}
      </Grid>
    </>
  );
}

