import React from "react";
import { withRouter } from "react-router-dom";
import { Breadcrumbs, Link, Grid, Typography } from "@material-ui/core";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

//  translation
import { useTranslation } from 'react-i18next';
import Widget from "../Widget/Widget";


function Breadcrumb({ location, history, structure }) {
  var classes = useStyles();
  //var theme = useTheme();
  var {t} = useTranslation();

  const handleClick = (e, link) => {
    e.preventDefault();
    history.push(link);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Widget header>
            <Breadcrumbs className={classes.breadcrumb} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link color="inherit" href="#" onClick={e=>handleClick(e,'/app')}>
                {t('Home')}
              </Link>
              {structure
                .map(mitem => (mitem.children) ? 
                  (mitem.children.filter(child => (location.pathname.match(child.link)))
                    .map(child => ([
                      <Link key={mitem.id} color="inherit" href="#" onClick={e=>handleClick(e,mitem.link)}>
                        {t(mitem.label)}
                      </Link>,
                      <Typography key={child.id} color="primary">{t(child.label)}</Typography>
                    ]
                    )) 
                  )
                : (location.pathname.match(mitem.link) && mitem.route && mitem.id!==0) &&  <Typography key={mitem.id} color="primary">{t(mitem.label)}</Typography>
              )}
            </Breadcrumbs>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(Breadcrumb);
