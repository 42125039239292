import React, { useEffect, useState} from "react";
import {
  Grid, TextField, CircularProgress, Button, LinearProgress, Select, InputLabel
} from "@material-ui/core";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

export default function CourseEdit(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 
  // global
  var userState = useUserState();
  const user = userState.user;

  const { pid, id } = props.match.params;

  // local
  const [courseData, setCourseData] = useState({"institue_id":"0","institue_group_id":"0","program_id":pid,"text_id":"0","teacher_id":"0","course_date":"","start_time":"09:00","end_time":"10:00","place":""});
  const [programData, setProgramData] = useState({"program_dates":""});
  const [institueGroups, setInstitueGroups] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [texts, setTexts] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("0");

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);

  async function fetchData(){

    let responses = await Promise.all([
      genericFetch(config.apiURL + '/records/institue_groups', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data => setInstitueGroups(data.records)),
      genericFetch(config.apiURL + '/records/texts', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data => setTexts(data.records)),
      genericFetch(config.apiURL + '/records/topics', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data => setTopics(data.records)),
      genericFetch(config.apiURL + '/records/users?filter=role_id,in,'+config.roles.teacher+','+config.roles.admin+','+config.roles.institue_admin, {...config.fetchConfig }, null, setIsLoading, setErr, setMsg)
        .then(data => setTeachers(data.records)),
    ])

    pid>0 && await genericFetch(config.apiURL + '/records/programs/'+pid, {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(data => setProgramData(data))

    id>0 && await genericFetch(config.apiURL + '/records/courses/'+id+"?join=texts", {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(courseDataRec => {
        setSelectedTopic(courseDataRec.text_id.topic_id);
        setCourseData({...courseDataRec, "text_id":courseDataRec.text_id._id}); 
      });
    
    return responses;
  }

  
  async function saveData(){   
    let url,fetchConfig;
    if(courseData._id === undefined){
      url = '/records/courses';
      fetchConfig = config.saveConfig;
    }else{
      url = '/records/courses/'+courseData._id; 
      fetchConfig = config.updateConfig;   
    }

    let response = await genericFetch(config.apiURL + url, {...fetchConfig, 
      body:JSON.stringify({...courseData, "institue_id":programData.institue_id, "program_id":pid}) }, t("Course successfully saved!"), setIsLoading, setErr, setMsg)
    
    return response;
  }

  useEffect(() => {
    fetchData();
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();  
    const response = saveData();
    console.log(response)
  }

  const handleChange = (e) => {
    const obj = { ...courseData, [e.target.name]:e.target.value};
    setCourseData(obj);
  }

  const handleTopicChange = (e) => {
    setSelectedTopic(e.target.value);
  }

  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }   
      {isLoading &&  <LinearProgress />}
      <PageTitle title={t("Course Management") + " - " + programData.program_title} /> 
      <Grid item xs={12} md={9}>
        <Widget title={t("Course Info" )} disableWidgetMenu>
          <div className={classes.formContainer}>
            <div className={classes.form}>
              <form className={classes.root} noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
                <div>
                  <InputLabel htmlFor="institue-group-select">{t("Group")}</InputLabel>
                  <Select
                    native
                    className={classes.selectField}
                    value={courseData.institue_group_id}
                    onChange={e => handleChange(e)}
                    inputProps={{
                      name: 'institue_group_id',
                      id: 'institue-group-select',
                    }}
                  >
                    <option key="0" value="0" >{t("Please select")}</option>
                    {institueGroups.map(institueGroup => (
                      <option key={institueGroup._id} value={institueGroup._id}>{institueGroup.group_name}</option>
                    ))}
                  </Select>
                </div>
                <div>
                  <InputLabel htmlFor="topic-id-select">{t("Topic")}</InputLabel>
                  <Select
                    native
                    className={classes.selectField}
                    value={selectedTopic}
                    onChange={e => handleTopicChange(e)}
                    inputProps={{
                      name: 'topic_id',
                      id: 'topic-id-select',
                    }}
                  >
                    <option key="0" value="0" >{t("Please select")}</option>
                    {topics.map(topic => (
                      <option key={topic._id} value={topic._id}>{topic.topic_name}</option>
                    ))}
                  </Select>
                </div>
                <div>
                  <InputLabel htmlFor="text-id-select">{t("Text")}</InputLabel>
                  <Select
                    native
                    className={classes.selectField}
                    value={courseData.text_id}
                    onChange={e => handleChange(e)}
                    inputProps={{
                      name: 'text_id',
                      id: 'text-id-select',
                    }}
                  >
                    <option key="0" value="0" >{t("Please select")}</option>
                    {texts.filter(text => text.topic_id == selectedTopic).map(text => (
                      <option key={text._id} value={text._id}>{text.title + "-" + text.institue_groups}</option>
                    ))}
                  </Select>
                </div>
                <div>
                  <InputLabel htmlFor="teacher-id-select">{t("Teacher")}</InputLabel>
                  <Select
                    native
                    className={classes.selectField}
                    value={courseData.teacher_id}
                    onChange={e => handleChange(e)}
                    inputProps={{
                      name: 'teacher_id',
                      id: 'teacher-id-select',
                    }}
                  >
                    <option key="0" value="0" >{t("Please select")}</option>
                    {teachers.map(teacher => (
                      <option key={teacher._id} value={teacher._id}>{teacher.name+" "+teacher.surname}</option>
                    ))}
                  </Select>
                </div>
                <div>
                  <InputLabel htmlFor="course_date-select">{t("Date")}</InputLabel>
                  <Select
                    native
                    className={classes.selectField}
                    value={courseData.course_date}
                    onChange={e => handleChange(e)}
                    inputProps={{
                      name: 'course_date',
                      id: 'course_date-select',
                    }}
                  >
                    <option key="0" value="0" >{t("Please select")}</option>
                    {programData.program_dates.split(",").map(date => (
                      <option key={date} value={date}>{date}</option>
                    ))}
                  </Select>
                </div>
                <div>
                  <TextField name="start_time" label={t("Start Time")} type="time" className={classes.textField} 
                            value={courseData.start_time}
                            onChange={e => handleChange(e)}/>
                  <TextField name="end_time" label={t("End Time")} type="time" className={classes.textField} 
                            value={courseData.end_time}
                            onChange={e => handleChange(e)}/>
                </div>
                <div>
                  <TextField name="place" label={t("Location")} type="text" className={classes.textField} 
                            value={courseData.place}
                            onChange={e => handleChange(e)}/>
                </div>

                <div className={classes.formButtons}>
                  <Button
                    disabled={
                      (courseData.text_id && courseData.teacher_id) ? false : true
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {t("Save")}
                  </Button>
                  <Button
                    color="secondary"
                    size="large"
                    className={classes.cancelButton}
                    onClick={e => goBack(e)}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Widget>

      </Grid>
    </>
  );
}
