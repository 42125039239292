import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  IconButton,
  TextField,
  Fade, CardMedia
} from "@material-ui/core";
import { Visibility, VisibilityOff} from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// traanslation
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// logo
import logo from "../../images/logo.svg";

// context
import { useUserDispatch, setUserPwd, useUserState } from "../../context/UserContext";

// config
import config from "../../config";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  var userState = useUserState();
  var { t } = useTranslation(); 

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [myData, setMyData] = useState(0);
  const [password, setPassword] = useState({"pass1":"","pass2":"","showPassword":false,"pass1Err":false,"pass2Err":false,"pass1ErrMsg":"","pass2ErrMsg":"","valid":false});

  const { uuid } = props.match.params;

  const handlePassChange = (e) => {
    let tmpPass = { ...password, [e.target.name]:e.target.value};
    tmpPass.valid=false;
    tmpPass.pass1Err=false;
    tmpPass.pass2Err=false;
    tmpPass.pass1ErrMsg="";
    tmpPass.pass2ErrMsg="";

    if(tmpPass.pass1.length > 0 && tmpPass.pass1.length < 5){
      tmpPass.pass1Err=true; tmpPass.pass1ErrMsg = t("Password length should be more than 5 characters");
    }
    if(tmpPass.pass2.length > 0  && tmpPass.pass1 !== tmpPass.pass2){
      tmpPass.pass2Err=true; tmpPass.pass2ErrMsg = t("Passwords are not matching");
    }
    if(tmpPass.pass1.length > 0 && tmpPass.pass2.length > 0 && !tmpPass.pass1Err && !tmpPass.pass2Err) 
      tmpPass.valid=true;

    setPassword(tmpPass);
    (tmpPass.valid) && setMyData({...myData,"password":password.pass1});
  }

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={8} className={classes.logotypeContainer}>

          <img src={logo} alt="logo" className={classes.logotypeImage} />
          <Typography className={classes.logotypeText}>{config.appName}</Typography>

      </Grid>
      <Grid item xs={12} sm={12} md={4} className={classes.formContainer}>

          <div className={classes.form}>
            <form className={classes.root} noValidate autoComplete="off" >  
              <CardMedia
                className={classes.media}
                image={logo}
                title={config.appName}
              />
              <Fade in={userState.error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {t(userState.message)}
                </Typography>
              </Fade>
              <Fade in={userState.success}>
                <Typography color="primary" className={classes.errorMessage}>
                  {t(userState.message)}
                </Typography>
              </Fade>
              <div>
                <Typography color="secondary" variant="h6">
                  {t("Type new password...")}
                </Typography>
              </div>
              <div>
                <TextField 
                  label={t("Password")}
                  name="pass1"
                  type={password.showPassword ? 'text' : 'password'}
                  value={password.pass1}
                  onChange={e => handlePassChange(e)}
                  className={classes.textField} 
                  error={password.pass1Err}
                  helperText={password.pass1ErrMsg}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {password.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              </div>   
              <div>
                <TextField 
                  label={t("Retype Password")}
                  name="pass2"
                  type={password.showPassword ? 'text' : 'password'}
                  value={password.pass2}
                  onChange={e => handlePassChange(e)}
                  className={classes.textField} 
                  error={password.pass2Err}
                  helperText={password.pass2ErrMsg}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {password.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <div className={classes.formButtons}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button
                      disabled={
                        (password.valid) ? false : true
                      }
                      onClick={() =>
                        setUserPwd(
                          userDispatch,
                          uuid,
                          password.pass1,
                          props.history,
                          setIsLoading,
                        )
                      }
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("Save")}
                    </Button>
                  )}
                  <Button
                    onClick={() =>
                      props.history.push("/")
                    }
                    variant="contained"
                    color="secondary"
                    size="large"
                    >
                    {t("Login")}
                  </Button>
                </div>
            </form>
          </div>
          <Typography color="primary" className={classes.copyright}>
            © 2020 - Hamburg {config.appName}
          </Typography>
      </Grid>
    </Grid>
  );
}

export default withRouter(Login);
