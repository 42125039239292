import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%", /* Player ratio: 100 / (1280 / 720) */
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  }
}));
