import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }
}));
