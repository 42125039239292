import React, { useEffect, useState } from "react";
import {
  Grid, TextField, CircularProgress, Button, InputLabel, Select
} from "@material-ui/core";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";


export default function AssessmentEdit(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // local
  const [assessmentData, setAssessmentData] = useState({"institue_id":"0","assessment_type":"0","assessment_text":" ","options":" "});
  const [institues, setInstitues] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  async function fetchData(){
    const { id } = props.match.params;

    genericFetch(config.apiURL + '/records/institues', {...config.fetchConfig }, null, setIsLoading, setErr, setMsg)
      .then(data=>setInstitues(data.records));

    id>0 && genericFetch(config.apiURL + '/records/assessments/'+id, {...config.fetchConfig }, null, setIsLoading, setErr, setMsg)
      .then(data=>setAssessmentData(data));

  }

  async function saveData(){   
    let url,fetchConfig;
    if(assessmentData._id === undefined){
      url = '/records/assessments';
      fetchConfig = config.saveConfig;
    }else{
      url = '/records/assessments/'+assessmentData._id; 
      fetchConfig = config.updateConfig;   
    }

    let response = await genericFetch(config.apiURL + url, {...fetchConfig, body:JSON.stringify(assessmentData) }, 
        t("Assessment successfully saved!"), setIsLoading, setErr, setMsg)

    return response;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();  
    saveData();
  }

  const handleChange = (e) => {
    let obj = { ...assessmentData, [e.target.name]:e.target.value};
    setAssessmentData(obj);
  }

  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }


  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }  
      <PageTitle title={t("Assessment Management")} />     
      <Grid item xs={9} md={9}>
        <Widget title={t("Assessment Info" )} disableWidgetMenu>
          <div className={classes.formContainer}>
            <div className={classes.form}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <>
                <form className={classes.root} noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
                  <div>
                    <InputLabel htmlFor="institue-select">{t("Institue")}</InputLabel>
                    <Select
                      native
                      className={classes.selectField}
                      value={assessmentData.institue_id}
                      onChange={e => handleChange(e)}
                      inputProps={{
                        name: 'institue_id',
                        id: 'institue-select',
                      }}
                    >
                      <option key="0" value="0" >{t("Please select")}</option>
                      {institues.map(institue => (
                        <option key={institue._id} value={institue._id}>{institue.institue_name}</option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <InputLabel htmlFor="type-select">{t("Assessment Type")}</InputLabel>
                    <Select
                      native
                      className={classes.selectField}
                      value={assessmentData.assessment_type}
                      onChange={e => handleChange(e)}
                      inputProps={{
                        name: 'assessment_type',
                        id: 'type-select',
                      }}
                    >
                      <option key="0" value="0" >{t("Please select")}</option>
                      {[{_id:1,name:t("Course Assessment")},{_id:2,name:t("General Assessment")}].map(tp => (
                        <option key={tp._id} value={tp._id}>{tp.name}</option>
                      ))}
                    </Select>
                  </div>                  
                  <div>
                    <TextField name="assessment_text" label={t("Assessment Text")} type="text" className={classes.textField} 
                              value={assessmentData.assessment_text}
                              onChange={e => handleChange(e)}/>
                  </div>
                  <div>
                    <TextField name="options" label={t("Options")} type="text" className={classes.textField} 
                              value={assessmentData.options}
                              onChange={e => handleChange(e)}/>
                  </div>
                  <div className={classes.formButtons}>
                    <Button
                      disabled={
                        (assessmentData &&  assessmentData.assessment_text &&  assessmentData.assessment_text.length === 0) ? true : false
                      }
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("Save")}
                    </Button>
                    <Button
                      color="secondary"
                      size="large"
                      className={classes.cancelButton}
                      onClick={e => goBack(e)}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </>
            )}
            </div>
          </div>
        </Widget>
      </Grid>
    </>
  );
}
