import React, {useState, useEffect} from "react";
import {IconButton , LinearProgress, Button} from "@material-ui/core";
import { Document, Page, pdfjs } from 'react-pdf';
import { Launch as LaunchIcon, ChevronRight, ChevronLeft, ZoomIn, ZoomOut} from "@material-ui/icons";


import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";


export default function CustomDocument(props) {
  var classes = useStyles();

  const {t} = useTranslation();

  const {url} = props;

  const [info, setInfo] = useState({"numPages":null,pageNumber:1});
  const [path, setPath] = useState("");
  const [scale, setScale] = useState(1.0);
  const [completed, setCompleted] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);


  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setInfo({ ...info, "numPages": numPages });
    setIsLoading(false);
  }
  const onError = (err) => {
    setError(err);
    setIsLoading(false);
  }
  
  const onLoadProgress = ({ loaded, total }) => {
    let progress = (loaded / total) * 100;
    console.log(progress)
    setCompleted(progress);
  }

  useEffect(() => {
    setPath({url:url,withCredentials: true, stopAtErrors:true});
    
  }, [])

  return (
    <>
      {error &&
        <Button endIcon={<LaunchIcon/>} href={url} target="_blank">
          Open
        </Button>
      }
      { isLoading && 
        <LinearProgress variant="determinate" value={completed} />  
      }
      { 
        <div>
          <div className={classes.formButtons}>
            <IconButton onClick={() => setInfo({...info, pageNumber: info.pageNumber - 1 })} disabled={info.pageNumber==1}>
              <ChevronLeft/>
            </IconButton>
            <IconButton onClick={() => setScale(scale - 0.1)} disabled={scale<0.5}>
              <ZoomOut/>
            </IconButton>   
            <p>{info.pageNumber +"/"+ info.numPages}</p>      
            <IconButton onClick={() => setScale(scale + 0.1)} disabled={scale>1.5}>
              <ZoomIn/>
            </IconButton>
            <IconButton onClick={() => setInfo({...info, pageNumber: info.pageNumber + 1 })} disabled={info.pageNumber==info.numPages}>
              <ChevronRight/>
            </IconButton>
          </div>
          <div className={classes.pdfContent}>
            <Document
              file={path} 
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onError}
              onLoadProgress={onLoadProgress}
            >
              <Page pageNumber={info.pageNumber} scale={scale}/>
            </Document>
          </div>
          <div className={classes.formButtons}>
            <IconButton onClick={() => setInfo({...info, pageNumber: info.pageNumber - 1 })} disabled={info.pageNumber==1}>
              <ChevronLeft/>
            </IconButton>
            <IconButton onClick={() => setScale(scale - 0.1)} disabled={scale<0.5}>
              <ZoomOut/>
            </IconButton>   
            <p>{info.pageNumber +"/"+ info.numPages}</p>      
            <IconButton onClick={() => setScale(scale + 0.1)} disabled={scale>1.5}>
              <ZoomIn/>
            </IconButton>
            <IconButton onClick={() => setInfo({...info, pageNumber: info.pageNumber + 1 })} disabled={info.pageNumber==info.numPages}>
              <ChevronRight/>
            </IconButton>
          </div>
        </div>
      }
    </>
  );
}
