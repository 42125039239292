import React, { useState, useEffect } from "react";
import {
  Grid, LinearProgress, List, ListItem, ListItemText, ListItemIcon, Divider, Collapse,
  Card, CardActionArea, CardMedia, CardActions, Switch, ListItemSecondaryAction, Button,
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
  InputLabel,Select, TextField,
} from "@material-ui/core";
import {  Description as DescriptionIcon, Subject as SubjectIcon, ExpandLess, ExpandMore, 
  YouTube as YouTubeIcon, ArrowRight as SubMenuIcon, AssignmentTurnedIn as AttendenceIcon, Assignment as AssessmentIcon} from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';
import MUIRichTextEditor from "mui-rte";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Toaster from "../../components/Toaster/Toaster";
import CustomDocument from "../../components/Document/Document";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {sortBy} from "../../helper";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";

export default function Course(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const userState = useUserState();
  const user = userState.user;

  //props
  const { textId, courseId, groupId } = props.match.params;

  // local
  const [textData, setTextData] = useState({});
  const [topicData, setTopicData] = useState({});
  const [documents, setDocuments] = useState([]);
  const [videos, setVideos] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [course, setCourse] = useState([]);
  const [program, setProgram] = useState([]);
  const [students, setStudents] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [courseAssessments, setCourseAssessments] = useState([]);
  const [checkedStudents, setCheckedStudents] = useState([]);
  const [menuIndex, setMenuIndex] = useState({"index":"0","object":{}});
  const [menuStates, setMenuStates] = useState({"documents":false,"videos":false});
  const [filter, setFilter] = useState({"program_id":"0","group_id":"0","program_date":"0"});

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(){

    let data = genericFetch(config.apiURL + '/records/courses/'+courseId+'?join=programs&join=texts,topics', {...config.fetchConfig}, 
      null, setIsLoading, setErr, setMsg)
      .then(data => {
        setCourse(data);
        setProgram(data.program_id);
        setTextData(data.text_id);
        setTopicData(data.text_id.topic_id);
        setDocuments(JSON.parse(data.text_id.uri));
        setVideos(JSON.parse(data.text_id.url));
      });

    
    courseId && genericFetch(config.apiURL + '/records/inspections?filter=course_id,eq,'+courseId , {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(inspectionsRec => {
        setCheckedStudents(inspectionsRec.records.filter(ins=>ins.flag>0).map(ins=>ins.student_id));
        setInspections(inspectionsRec.records);
      });
    
    groupId && genericFetch(config.apiURL + '/records/institue_group_users?filter=institue_group_id,eq,'+groupId + '&join=users', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(studentsRec => 
        setStudents(studentsRec.records.filter(rec=>rec.user_id.role_id === config.roles.student).map(rec=>rec.user_id))
      );

    courseId && genericFetch(config.apiURL + '/records/assessments?filter=assessment_type,eq,1', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
    .then(assessmentRec => 
      setAssessments(assessmentRec.records)
    );

    courseId && genericFetch(config.apiURL + '/records/course_assessments?filter=course_id,eq,'+courseId, {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
    .then(courseAssessmentRec => 
      setCourseAssessments(courseAssessmentRec.records)
    );
  

    return data;
  }


  const getInspectionsJson = () => {
    var attendenceList = [];
    students.map(student=>
      checkedStudents.includes(student._id) 
      ? attendenceList.push({"course_id":courseId,"teacher_id":user._id,"student_id":student._id, "flag":1})
      : attendenceList.push({"course_id":courseId,"teacher_id":user._id,"student_id":student._id, "flag":0})
    );
    
    return attendenceList;
  }

  async function saveAttendenceData(){   
    let url = (inspections.length) ? '/records/inspections/'+inspections.map(i => i._id).join(',') :'/records/inspections';
    let fetchConfig = (inspections.length) ? config.updateConfig : config.saveConfig;

    const attendenceList = getInspectionsJson();
    let result = await genericFetch(config.apiURL + url, {...fetchConfig, body: JSON.stringify(attendenceList)}, t('Inspections successfully saved!'), setIsLoading, setErr, setMsg)
    .then(data => {
      if(inspections.length==0) {
        let insTemp = [];
        data.map((res,index) => insTemp.push({...attendenceList[index],"_id":res}));
        setInspections(insTemp);
      }
    })
    
    return result;
  } 
  
  async function saveAssessmentsData(){  
    
    const cAUpdate = courseAssessments.filter(ca => ca._id !== undefined);
    if(cAUpdate.length){
      let url = '/records/course_assessments/'+cAUpdate.map(i => i._id).join(',');
      let result = await genericFetch(config.apiURL + url, {...config.updateConfig , body: JSON.stringify(cAUpdate)}, t('Assessments successfully saved!'), setIsLoading, setErr, setMsg);
    }

    const cAInsert = courseAssessments.filter(ca => ca._id === undefined);
    if(cAInsert.length){
      let url = '/records/course_assessments';
      let result = await genericFetch(config.apiURL + url, {...config.saveConfig , body: JSON.stringify(cAInsert)}, t('Assessments successfully saved!'), setIsLoading, setErr, setMsg)
      .then(data => {
        if(inspections.length==0) {
          let insTemp = [];
          data.map((res,index) => insTemp.push({...cAInsert[index],"_id":res}));
          setCourseAssessments(insTemp);
        }
      })
    }

  }  


  const handleAttendenceSubmit = (e) => {
    e.preventDefault();  
    saveAttendenceData();
  }

  const handleAssessmentSubmit = (e) => {
    e.preventDefault();  
    console.log(courseAssessments)
    saveAssessmentsData();
  }

  const handleAssessmentChange = (e, student_id, assessment_id) => {
    let found = 0
    let tmpAssessment = courseAssessments.map(ca => {
      let obj={};
      if(ca.student_id === student_id && ca.assessment_id === assessment_id){
        obj= {...ca, "value":e.target.value};
        found=1;
      }else{
        obj=ca;
      }
      return obj;
    });
    (found === 0) && tmpAssessment.push({"course_id":course._id,"teacher_id":user._id,"student_id":student_id,"assessment_id":assessment_id,"value":e.target.value});
    setCourseAssessments(tmpAssessment);
  };

  const handleMenuSelect = (index, object) => {
    setMenuIndex({"index":index,"object":object});
  };

  const handleMenuExpand = (item) => {
    setMenuStates({...menuStates, [item]:!menuStates[item]})
  };

  const handleToggle = (value) => () => {
    const currentIndex = checkedStudents.indexOf(value);
    const newChecked = [...checkedStudents];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedStudents(newChecked);
  };

  const handleToggleAll = (e) => {
    e.target.checked ? setCheckedStudents(students.map(student => student._id)) : setCheckedStudents([]);
  };

  const openText = (e, id) => {
    e.preventDefault();  
    props.history.push("/app/courses/texts/"+id);
  }

  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }  
      {isLoading ? <LinearProgress /> :
      <>
        <PageTitle title={program.program_title + " - " + course.course_date + " / " + t(topicData.topic_name)} />
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Widget title={t("Content")} disableWidgetMenu>
              <div className={classes.root}>
              <List component="nav">
                  <ListItem
                    button
                    selected={menuIndex.index === "0"}
                    onClick={(event) => handleMenuSelect("0",{})}
                    className={[menuIndex.index === "0" && classes.linkIconActive].join(' ')}
                  >
                    <ListItemIcon>
                      <SubjectIcon className={[menuIndex.index === "0" && classes.linkIconActive].join(' ')}/>
                    </ListItemIcon>
                    <ListItemText primary={t("Description")} />
                  </ListItem>
                </List>
                {groupId && courseId && 
                  <List component="nav">
                    <ListItem
                      button
                      selected={menuIndex.index === "1"}
                      onClick={(event) => handleMenuSelect("1",{})}
                      className={[menuIndex.index === "1" && classes.linkIconActive].join(' ')}
                    >
                      <ListItemIcon>
                        <AttendenceIcon className={[menuIndex.index === "1" && classes.linkIconActive].join(' ')}/>
                      </ListItemIcon>
                      <ListItemText primary={t("Attendence")} />
                    </ListItem>
                  </List>
                }
                {groupId && courseId && 
                  <List component="nav">
                    <ListItem
                      button
                      selected={menuIndex.index === "2"}
                      onClick={(event) => handleMenuSelect("2",{})}
                      className={[menuIndex.index === "2" && classes.linkIconActive].join(' ')}
                    >
                      <ListItemIcon>
                        <AssessmentIcon className={[menuIndex.index === "2" && classes.linkIconActive].join(' ')}/>
                      </ListItemIcon>
                      <ListItemText primary={t("Assessments")} />
                    </ListItem>
                  </List>
                }
                <Divider />
                <List component="nav">
                  <ListItem
                    button
                    onClick={(event) => handleMenuExpand("documents")}
                    className={[menuIndex.index >= 100 && menuIndex.index <200 && classes.linkIconActive].join(' ')}
                  >
                    <ListItemIcon>
                      <DescriptionIcon className={[menuIndex.index >= 100 && menuIndex.index <200 && classes.linkIconActive].join(' ')} />
                    </ListItemIcon>
                    <ListItemText primary={t("Documents")} />
                    {menuStates.documents ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={menuStates.documents} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {documents.map((document,dindex) => 
                      <ListItem key={dindex}
                        button
                        className={[classes.nested, menuIndex.index === dindex+100 && classes.linkIconActive].join(' ')}
                        selected={menuIndex.index === dindex+100}
                        onClick={(event) => handleMenuSelect(dindex+100, document)}
                      >
                        <ListItemIcon>
                          <SubMenuIcon className={[classes.nested, menuIndex.index === dindex+100 && classes.linkIconActive].join(' ')}/>
                        </ListItemIcon>
                        <ListItemText primary={t(document.text)} />
                      </ListItem>
                    )}
                    </List>
                  </Collapse>
                </List>
                <List component="nav">
                  <ListItem
                    button
                    onClick={(event) => handleMenuExpand("videos")}
                    className={[menuIndex.index >=200 && menuIndex.index<300 && classes.linkIconActive].join(' ')}
                  >
                    <ListItemIcon>
                      <YouTubeIcon className={[menuIndex.index >=200 && menuIndex.index<300 && classes.linkIconActive].join(' ')}/>
                    </ListItemIcon>
                    <ListItemText primary={t("Videos")} />
                    {menuStates.videos ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={menuStates.videos} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {videos.map((video,vindex) => 
                      <ListItem key={vindex}
                        button
                        className={[classes.nested, menuIndex.index === vindex+200 && classes.linkIconActive].join(' ')}
                        selected={menuIndex.index === vindex+200}
                        onClick={(event) => handleMenuSelect(vindex+200,video)}
                      >
                        <ListItemIcon>
                          <SubMenuIcon className={[classes.nested, menuIndex.index === vindex+200 && classes.linkIconActive].join(' ')}/>
                        </ListItemIcon>
                        <ListItemText primary={t(video.desc)} />
                      </ListItem>
                    )}
                    </List>
                  </Collapse>
                </List>
              </div>
            </Widget>
          </Grid>


          <Grid item xs={12} sm={12} md={8} lg={9}>
            <Widget title={t(textData.title )} disableWidgetMenu>
              { (menuIndex.index == "0") &&
                <Grid item xs={12} >
                  <div>
                    <MUIRichTextEditor
                      label={t("Description")}
                      value={textData.text}
                      readOnly={true}
                      toolbar={false}
                    />
                  </div>
                </Grid>
              }
              { (menuIndex.index >= 100 && menuIndex.index < 200) &&
                  documents.filter(doc => doc.uid === menuIndex.object.uid).map((doc,index) => (
                    <Grid key={index} item xs={12} >
                      {(doc.mime.includes("image")) ?
                      <Card variant="outlined">
                        <CardActionArea>
                          <CardMedia
                            className={classes.media}
                            image={config.imageapiURL + doc.uid}
                            title={doc.text}
                          />
                        </CardActionArea>
                        <CardActions>
                        </CardActions>
                      </Card>
                      :
                      <CustomDocument url={config.imageapiURL + doc.uid}/>
                      }
                    </Grid>
                  ))}
              { (menuIndex.index >= 200 && menuIndex.index < 300) &&
                <Grid item xs={12} >
                  {videos.filter(vid => vid.uid === menuIndex.object.uid).map((vid) => (
                    <VideoPlayer url={vid.link} />
                  ))}
                </Grid>
              }

              { (menuIndex.index == 1) &&
                <Grid item xs={12} >
                  <div>
                    <List dense={true}>
                      <ListItem key={0}>
                          <ListItemText
                            primary={<Typography size="md" >
                                      {t("Student")}
                                    </Typography> 
                                    }
                          />
                          <ListItemSecondaryAction>
                            <Switch
                              edge="end"
                              onChange={e => handleToggleAll(e)}
                              checked={checkedStudents.length === students.length}
                              inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    {students.map((student,index)=>
                        <ListItem key={index}>
                          <ListItemText
                            primary={student.name+" "+student.surname}
                          />
                          <ListItemSecondaryAction>
                            <Switch
                              edge="end"
                              onChange={handleToggle(student._id)}
                              checked={checkedStudents.indexOf(student._id) !== -1}
                              inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      )}
                    </List>
                  </div>
                  <div className={classes.formButtons}> 
                    <Button
                      onClick={e=>handleAttendenceSubmit(e)}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("Save")}
                    </Button>
                  </div>
                  
                </Grid>
              }
              
              
              { (menuIndex.index == 2) &&
                <Grid item xs={12} >
                  <div>
                    {students.map((student,index)=>
                      <ExpansionPanel key={index}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading} color="secondary">{student.name+" "+student.surname}</Typography>
                          <Typography className={classes.secondaryHeading}>{course.text_id.title}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <div key={index} className={classes.formContainer}>
                            <div className={classes.form}>
                              { assessments.map((assessment,index) =>
                                <>
                                {assessment.options ? 
                                  <div>
                                    <InputLabel htmlFor="assesment-select">{assessment.assessment_text}</InputLabel>
                                    <Select
                                      native
                                      className={classes.selectField}
                                      value={courseAssessments.filter(cassessment=>cassessment.student_id === student._id && cassessment.assessment_id === assessment._id).map(cass => cass.value).join()}                                      
                                      onChange={(e)=>handleAssessmentChange(e,student._id, assessment._id)}
                                      inputProps={{
                                        name: 'assessment_id',
                                        id: 'assesment-select',
                                      }}
                                    >
                                      <option key="0" value="0" >{t("Please select")}</option>
                                      {assessment.options.split(',').map(option => (
                                        <option key={option} value={option}>{t(option)}</option>
                                      ))}
                                    </Select>
                                </div>
                                :
                                <div>
                                  <TextField name="assessment_id_x" label={assessment.assessment_text} type="text" className={classes.textField} 
                                              value={courseAssessments.filter(cassessment=>cassessment.student_id === student._id && cassessment.assessment_id === assessment._id).map(cass => cass.value).join()}
                                              onChange={(e)=>handleAssessmentChange(e,student._id, assessment._id)}/>
                                </div>
                                }
                                </>
                              )}
                            </div>
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      )}
                  </div>
                  <div className={classes.formButtons}> 
                    <Button
                      onClick={e=>handleAssessmentSubmit(e)}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("Save")}
                    </Button>
                  </div>
                  
                </Grid>
              }
              
            </Widget>
          </Grid>
        </Grid>
      </>
      }
    </>
  );
}

