import React, { useState, useEffect } from "react";
import {
  Grid, LinearProgress, Switch,Button,
  List, ListItem, ListItemText, ListItemSecondaryAction, Divider, IconButton
} from "@material-ui/core";
import { AssignmentTurnedIn as InspectionIcon,  FilterList as FilterListIcon, ExpandMore as ExpandMoreIcon} from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {sortBy,dedupArr} from "../../helper";



export default function Inspections(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const userState = useUserState();
  const user = userState.user;

  // local
  const [students, setStudents] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [myCourse, setMyCourse] = useState({"institue_group_id":{"group_name":""},"program_id":{"program_title":""},"text_id":{"topic_id":{"topic_name":""},"title":""},"course_date":"","start_time":"","end_time":""});
  const [checkedStudents, setCheckedStudents] = useState([]);
  const [filter, setFilter] = useState({"program_id":"0","group_id":"0","program_date":"0"});

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);

  const {courseId, groupId} = props.match.params;


  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(){

    courseId && genericFetch(config.apiURL + '/records/courses/'+courseId+'?join=programs&join=institue_groups&join=texts,topics', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
    .then(course => setMyCourse(course));

    groupId && genericFetch(config.apiURL + '/records/institue_group_users?filter=institue_group_id,eq,'+groupId + '&join=users', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
    .then(studentsRec => setStudents(studentsRec.records.filter(rec=>rec.user_id.role_id === config.roles.student).map(rec=>rec.user_id)));

    courseId && genericFetch(config.apiURL + '/records/inspections?filter=course_id,eq,'+courseId , {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
    .then(inspectionsRec => {
      setCheckedStudents(inspectionsRec.records.filter(ins=>ins.flag>0).map(ins=>ins.student_id));
      setInspections(inspectionsRec.records);
    });

    return null;
  }


  async function saveData(){   
    let url = (inspections.length) ? '/records/inspections/'+inspections.map(i => i._id).join(',') :'/records/inspections';
    let fetchConfig = (inspections.length) ? config.updateConfig : config.saveConfig;

    let result = await genericFetch(config.apiURL + url, {...fetchConfig, body: JSON.stringify(getInspectionsJson())}, t('Inspections successfully saved!'), setIsLoading, setErr, setMsg)
      
    return result;
  }  


  const handleSubmit = (e) => {
    e.preventDefault();  
    saveData();
  }

  const handleFilter = (e) => {
    const obj = { ...filter, [e.target.name]:e.target.value};
    setFilter(obj);
  }

  const getInspectionsJson = () => {
    var ugroups = [];
    students.map(student=>
      checkedStudents.includes(student._id) 
      ? ugroups.push({"course_id":courseId,"teacher_id":user._id,"student_id":student._id, "flag":1})
      : ugroups.push({"course_id":courseId,"teacher_id":user._id,"student_id":student._id, "flag":0})
    );
    
    return ugroups;
  }

  const openInspection = (e) => {
    e.preventDefault();  
    console.log(myCourse)
    console.log(students)
    console.log(checkedStudents)
  }

  const handleToggle = (value) => () => {
    const currentIndex = checkedStudents.indexOf(value);
    const newChecked = [...checkedStudents];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedStudents(newChecked);
  };

  const handleToggleAll = (e) => {
    e.target.checked ? setCheckedStudents(students.map(student => student._id)) : setCheckedStudents([]);
  };


  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }


  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg} />}
      {isLoading && <LinearProgress />}
      <PageTitle title={myCourse.program_id.program_title +" / "+ myCourse.institue_group_id.group_name} />

      <Grid container spacing={4}>
        <Grid item xs={12} sm={10} md={10} lg={8} xl={6}>
          <Widget title={myCourse.text_id.topic_id.topic_name +" / "+ myCourse.text_id.title} disableWidgetMenu >
            <Typography className={classes.inline} color="primary">
              {myCourse.course_date + " / " +myCourse.start_time.toString().substring(0,5)+"-"+myCourse.end_time.toString().substring(0,5)}
            </Typography>  
            <List dense={true}>
              <ListItem key={0}>
                  <ListItemText
                    primary={<Typography size="md" >
                              {t("Student")}
                            </Typography> 
                            }
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={e => handleToggleAll(e)}
                      checked={checkedStudents.length === students.length}
                      inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
            {students.map((student,index)=>
                <ListItem key={index}>
                  <ListItemText
                    primary={student.name+" "+student.surname}
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={handleToggle(student._id)}
                      checked={checkedStudents.indexOf(student._id) !== -1}
                      inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>

            <div className={classes.formButtons}>
              <Button
                disabled={
                  (!checkedStudents) ? true : false
                }
                onClick={e=>handleSubmit(e)}
                variant="contained"
                color="primary"
                size="large"
              >
                {t("Save")}
              </Button>
              <Button
                color="secondary"
                size="large"
                className={classes.cancelButton}
                onClick={e => goBack(e)}
              >
                {t("Cancel")}
              </Button>
            </div>  
          </Widget>
        </Grid>
      </Grid>
      

    </>
  );
}

