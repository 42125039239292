import React, { useEffect, useState } from "react";
import {
  Grid, TextField, CircularProgress, Button, LinearProgress, 
  Select, InputLabel,Chip, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, Divider
} from "@material-ui/core";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  //PictureAsPdf as PDFIcon,
  //Image as ImageIcon,
  Description as TextIcon,
  YouTube as YouTubeIcon
} from "@material-ui/icons";
import {Autocomplete} from '@material-ui/lab';
import MUIRichTextEditor from "mui-rte";
import { convertToRaw } from 'draft-js'
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Toaster from "../../components/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {dedup, UUID} from "../../helper";



export default function TextEdit(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  var userState = useUserState();
  const user = userState.user;
  // params
  const { tid, id } = props.match.params;

  // local
  const [textData, setTextData] = useState({"topic_id":tid,"user_id":user._id,"title":"","tags":"","text":"","short_desc":"","uri":"[]","url":"[]","institue_groups":""});
  const [topics, setTopics] = useState([]);
  const [institueGroups, setInstitueGroups] = useState([]);
  const [tags, setTags] = useState([]);
  const [textTmp, setTextTmp] = useState(null);
  const [uris64, setUris64] = useState([]);
  const [link, setLink] = useState("");
  const [linkDesc, setLinkDesc] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);

  async function fetchData(){

    let responses = await Promise.all([
      genericFetch(config.apiURL + '/records/topics/', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(data => setTopics(data.records)),
      genericFetch(config.apiURL + '/records/texts?include=texts.tags', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(data => {
        let allTags = [];
        data.records.forEach(element => {
          element.tags.split(",").forEach(tag => {
            allTags.push(tag)
          })
        });
        setTags(dedup(allTags));
      }),
      genericFetch(config.apiURL + '/records/institue_groups?include=group_name', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data => {setInstitueGroups(data.records.map(grp=>grp.group_name))}),
    ])

    id>0 && await genericFetch(config.apiURL + '/records/texts/'+id, {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
                  .then(data => {
                    setTextData(data);
                    setTextTmp(data.text);
                  });
    
    return responses;
  }

  async function uploadFiles(){
    let result = uris64.map(async uri => {
      const formData  = new FormData();
      formData.append('file_base64', uri.data);
      formData.append('filename', uri.uid);

      let response = await genericFetch(config.imageapiURL + uri.uid, {...config.fileSaveConfig, body:formData }, null, setIsLoading, setErr, setMsg);
      
      return response;
    })
    setUris64([]);
    return result;
  }
  
  async function saveData(){   
    let url,fetchConfig;

    if(textData._id === undefined){
      url = '/records/texts';
      fetchConfig = config.saveConfig;
    }else{
      url = '/records/texts/'+textData._id; 
      fetchConfig = config.updateConfig;   
    }

    if(uris64.length>0)
      await uploadFiles();
    
    let response = await genericFetch(config.apiURL + url, {...fetchConfig, body:JSON.stringify(textData) }, t("Text successfully saved!"), setIsLoading, setErr, setMsg)
    
    return response;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();  
    saveData();
  }

  const handleChange = (e) => {
    let obj = { ...textData, [e.target.name]:e.target.value, "user_id":user._id };
    setTextData(obj);
  }

  const handleTagsChange = (event, values) => {
    let obj = { ...textData, "tags":values.join(","), "user_id": user._id};
    setTextData(obj);
  }

  const handleGroupsChange = (event, values) => {
    let obj = { ...textData, "institue_groups":values.join(","), "user_id": user._id};
    setTextData(obj);
  }

  const handleEditorSave = data => {
    let obj = { ...textData, "text":data, "user_id": user._id};
    setTextData(obj);
  }

  const handleAutoSave = state => {
    const data = JSON.stringify(convertToRaw(state.getCurrentContent()));
    let obj = { ...textData, "text":data, "user_id": user._id};
    setTextData(obj);
  }

  const addUri = ({ target }) => {
    if(target.files[0]){
      setIsLoading(true)
      const file = target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
          const name = file.name;
          const mime = file.type;
          const type = name.split(".").pop();
          const uid = UUID() + "." +type;

          setTextData({...textData, uri:JSON.stringify([...JSON.parse(textData.uri), {"uid":uid,"text":name,"type":type,"mime":mime}])}); 
          setUris64([...uris64, {"uid":uid,"data":e.target.result}]);
          setIsLoading(false);
      }
    };
  };

  const removeUri = (e, uid) => {
    e.preventDefault();
    setTextData({...textData, uri:JSON.stringify(JSON.parse(textData.uri).filter(uri=> uri.uid !== uid))});
    setUris64(uris64.filter(uri=> uri.uid !== uid));
  }

  const addLink = (e) => {
    e.preventDefault();  
    const uid = UUID();
    setTextData({...textData, url:JSON.stringify([...JSON.parse(textData.url), {"uid":uid,"link":link,"type":"video","desc":linkDesc}])}); 
  }

  const removeLink = (e, uid) => {
    e.preventDefault();
    setTextData({...textData, url:JSON.stringify(JSON.parse(textData.url).filter(url=> url.uid !== uid))});
  }

  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }  
      {isLoading &&  <LinearProgress />}
      <PageTitle title={t("Text Management")} />    
      <Grid item xs={12}>
        <Widget title={t("Text Info" )} disableWidgetMenu>
          <div className={classes.formContainer}>
            <div className={classes.form}>
              <form className={classes.root} noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
                <div>
                  <InputLabel htmlFor="topic-select">{t("Topic")}</InputLabel>
                  <Select
                    native
                    className={classes.selectField}
                    value={textData.topic_id}
                    onChange={e => handleChange(e)}
                    inputProps={{
                      name: 'topic_id',
                      id: 'topic-select',
                    }}
                  >
                    {topics.map(topic => (
                      <option key={topic._id} value={topic._id}>{topic.topic_name}</option>
                    ))}
                  </Select>
                </div>
                <div>
                  <TextField name="title" label={t("Title")} type="text" className={classes.textField} 
                              value={textData.title}
                              onChange={e => handleChange(e)}/>
                </div>
                <div>
                  <TextField name="short_desc" label={t("Short Description")} type="text" className={classes.textField} 
                              value={textData.short_desc}
                              onChange={e => handleChange(e)}/>
                </div>
                <div>
                  <Autocomplete
                    multiple
                    id="institue_groups"
                    options={institueGroups}
                    value={textData.institue_groups.split(',').filter(function(el) {return el.length !== 0})}
                    renderInput={(params) => (
                      <TextField {...params} name="institue-groups-input" label={t("Groups")} placeholder={t("Groups")} className={classes.textField} />
                    )}
                    onChange={handleGroupsChange}
                  />
                </div>
                <div>
                  <Autocomplete
                    multiple
                    id="tags"
                    options={tags}
                    value={textData.tags.split (',').filter(function(el) {return el.length !== 0})}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} name="tags-input" label={t("Tags")} placeholder={t("Hit enter to save tag")} className={classes.textField} />
                    )}
                    onChange={handleTagsChange}
                  />
                </div>
                <div className={classes.dashedBorder}>
                  <MUIRichTextEditor
                    label={t("Type something here...")}
                    value={textTmp}
                    onSave={handleEditorSave}
                    inlineToolbar={true}
                    onChange={handleAutoSave}
                  />
                </div>
                
                <div className={classes.formButtons}>
                  <InputLabel>{t("Documents")}</InputLabel>
                  <input
                    accept=".pdf, .doc, .docx, image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={addUri}
                  />
                  <label htmlFor="raised-button-file">
                    <IconButton component="span">
                      <AddIcon/>
                    </IconButton>
                  </label> 
                </div>
                <div>
                  <List>
                    {JSON.parse(textData.uri).map((uri,index) => 
                    [
                      <ListItem key={index}>
                        <ListItemIcon>
                          <TextIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={uri.text}
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" onClick={e => removeUri(e, uri.uid)}>
                            <RemoveIcon/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>,
                      <Divider key={100+index}/>
                    ]
                    )}
                  </List>
                </div>


                <div>
                  <div className={classes.formColumnTexts}>
                    <YouTubeIcon />
                    <TextField name="link" label={t("Video Link")} type="text" className={classes.textFieldColumn} 
                                value={link}
                                onChange={e => setLink(e.target.value)}/>
                    <TextField name="desc" label={t("Description")} type="text" className={classes.textFieldColumn} 
                                value={linkDesc}
                                onChange={e => setLinkDesc(e.target.value)}/>
                    <IconButton component="span" onClick={e => addLink(e)}>
                      <AddIcon/>
                    </IconButton>
                  </div>
                  <List>
                    {JSON.parse(textData.url).map((url,index) => 
                    [
                      <ListItem key={index}>
                        <ListItemIcon>
                          <YouTubeIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={url.desc}
                          secondary={
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {url.link}
                              </Typography>                              
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" onClick={e => removeLink(e, url.uid)}>
                            <RemoveIcon/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>,
                      <Divider key={100+index}/>
                    ]
                    )}
                  </List>
                </div>

                <div className={classes.formButtons}>
                  <Button
                    disabled={
                      (textData && textData.title && textData.title.length === 0) ? true : false
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {t("Save")}
                  </Button>
                  <Button
                    color="secondary"
                    size="large"
                    className={classes.cancelButton}
                    onClick={e => goBack(e)}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Widget>
      </Grid>
    </>
  );
}
