import React from "react";
import config  from "../config"

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, error:false, user: action.user, messages: action.messages };
    case "REFRESH_MESSAGES":
        return { ...state, messages: action.messages };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false, error: true, user: null, message: action.message};
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, error:false, user: null, messages: null};
    case "SESSION_EXPIRED":
        return { ...state, isAuthenticated: false};
    case "REGISTER_SUCCESS":
      return { ...state, isAuthenticated: false, error:false, user: null, success:true, message: action.message};
    case "REGISTER_FAILURE":
        return { ...state, isAuthenticated: false, error:true, user: null, message: action.message};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("user"),
    user: JSON.parse(localStorage.getItem("user")),
    messages: JSON.parse(localStorage.getItem("usermessages")),
    error:false,
    message : ""
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, sessionExpired, registerUser, resetUserPwd, setUserPwd, refreshMessages};

// ###########################################################

async function refreshMessages(user, dispatch){
  let messages = await fetch(config.apiURL + '/records/message_receivers?filter=receiver_id,eq,'+user._id+'&filter=unread_messages,gt,0&join=message_groups', {...config.fetchConfig})
    .then(res => res.json());

  let urmessages = (messages.records.length) 
  ? messages.records.map(mgr=>{return {...mgr.message_group_id,"unread_messages":mgr.unread_messages,"rcv_id":mgr._id}})
  : "" ;
  localStorage.setItem('usermessages', JSON.stringify(urmessages));
  dispatch && dispatch({ type: "REFRESH_MESSAGES" , messages: urmessages});

  return urmessages;
}

function loginUser(dispatch, login, password, history, setIsLoading) {
  setIsLoading(true);

  if (!!login && !!password) {
    fetch( config.apiURL + '/login', {
      ...config.loginConfig,
      body: JSON.stringify({
        "username": login, "password" : password
      })
    })
      .then(response => response.json())
      .then(tmpuser => {
        // get user data
        fetch( config.apiURL + '/records/users/'+tmpuser._id+'?join=roles&join=institues', {
          ...config.fetchConfig})
          .then(res => res.json())
          .then(user => {
            if(user && user.code){
              dispatch({ type: "LOGIN_FAILURE" , message: user.message});
              setIsLoading(false);
            }else{
                refreshMessages(user,null)
                  .then(urmessages=>{
                    localStorage.setItem('usermessages', JSON.stringify(urmessages));
                    localStorage.setItem('user', JSON.stringify(user));
                    setIsLoading(false);
                    dispatch({ type: 'LOGIN_SUCCESS' , user: user, messages:urmessages });
                    history.push('/app/dashboard');
                  });
            }
          })
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        dispatch({ type: "LOGIN_FAILURE" , message: error.message});              
      });
  }
}

function registerUser(dispatch, name, surname, email, phone, adress, birthdate, institue_id, role_id, setIsLoading) {
  setIsLoading(true);

  if (!!email && !!name && !!surname && !!phone && !!adress && !!birthdate ) {
    
    fetch( config.registerapiURL + 'req=register&lang=tr', {
      ...config.saveConfig,
      body: JSON.stringify({
        "name": name, "surname" : surname, "email": email, "phone": phone, "adress": adress, "birthdate":birthdate, "institue_id": institue_id, "role_id": role_id
      })
    })
      .then(response => response.json())
      .then(tmpuser => {
        // get user data
        console.log(tmpuser)
        dispatch({ type: "REGISTER_SUCCESS" , message: "Thank you for your registration! You will be informed shortly via e-mail."});  
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        dispatch({ type: "REGISTER_FAILURE" , message: error.message});              
      });
  }
}


function resetUserPwd(dispatch, email, setIsLoading) {
  setIsLoading(true);

  if (!!email ) {
    
    fetch( config.registerapiURL + 'req=resetpwd&lang=tr', {
      ...config.saveConfig,
      body: JSON.stringify({
        "email": email
      })
    })
      .then(response => response.json())
      .then(tmpuser => {
        // get user data
        console.log(tmpuser)
        dispatch({ type: "REGISTER_SUCCESS" , message: "You will be informed shortly via e-mail to reset your password"});  
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        dispatch({ type: "REGISTER_FAILURE" , message: error.message});              
      });
  }
}



function setUserPwd(dispatch, uuid, password, history, setIsLoading) {
  setIsLoading(true);

  if (!!uuid ) {
    console.log(uuid);
    fetch( config.registerapiURL + 'req=setpassword&lang=tr', {
      ...config.saveConfig,
      body: JSON.stringify({
        "uuid": uuid, password: password
      })
    })
      .then(response => response.json())
      .then(tmpuser => {
        // get user data
        console.log(tmpuser)
        dispatch({ type: "REGISTER_SUCCESS" , message: "Your password is set successfully!"});  
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        dispatch({ type: "REGISTER_FAILURE" , message: error.message});              
      });
  }
}

function signOut(dispatch) {

  fetch( config.apiURL + '/logout', {
    ...config.saveConfig, body: ""
  })
  .then(res => res.json())
  .then((resJson) => {
    localStorage.removeItem("user");
    dispatch({ type: "SIGN_OUT_SUCCESS" });
  });
  
}

async function sessionExpired(dispatch) {
  localStorage.removeItem("user");
  let result = await fetch( config.apiURL + '/logout', {
    ...config.saveConfig, body: ""
  })
  .then(res => res.json())
  .then((resJson) => {
    dispatch({ type: "SESSION_EXPIRED" });
  });
  return result;
}