import React from 'react';
import {
  Box
} from "@material-ui/core";
//import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";


export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  var classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={classes.boxContainer}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
