


const genericFetch =  async(url, options, successMsg, setIsLoading, setErr, setMsg) => {
  

  setIsLoading(true);
  setErr(null);
  setMsg(null);
  
  let response = await fetch(url, options)
    .then(res => res.json())
    .catch((err) => setErr(err))
    .finally(() => setIsLoading(false));

  //console.log(response);
  if(response!==undefined){
    const {code, message} = response; 
    if(code || message){
      setErr(response);
    }else{
      if(['PUT','POST','DELETE'].includes(options.method) && response==0){
        setErr("Operation not performed!");
      }else{
        successMsg && setMsg(successMsg);
      }
    }  
  }
  
    
  return response;
};


export { genericFetch };