import React from "react";
import {Grid} from "@material-ui/core";

import { Typography } from "../../components/Wrappers/Wrappers";
import Widget from "../../components/Widget/Widget";

// styles
import useStyles from "./styles";


export default function NumberCard(props) {
  var classes = useStyles();
  const {icon, header, subtitle} = props;


  return (
    <>
      <Grid item xs={12} sm={4}>
          <Widget disableWidgetMenu>
                <Grid container justify="center" spacing={2}>
                    <Grid item>
                      <Typography size="xxl">
                        {icon}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography size="xxl">
                        {header}
                      </Typography>
                      <Typography size="lg">
                        {subtitle}
                      </Typography>
                    </Grid>
                </Grid>
          </Widget>
        </Grid>
    </>
  );
}
