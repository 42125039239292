import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Select
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// traanslation
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// logo
import logo from "../../images/logo.svg";
import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser, useUserState , resetUserPwd, registerUser} from "../../context/UserContext";

// config
import config from "../../config";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  var userState = useUserState();
  var { t } = useTranslation(); 

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [surnameValue, setSurnameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [institueValue, setInstitueValue] = useState(0);
  var [birthdateValue, setBirthdateValue] = useState("");
  var [adressValue, setAdressValue] = useState("");
  var [phoneValue, setPhoneValue] = useState("");
  var [roleValue, setRoleValue] = useState(0);

  const institues = [{"id":"1","name":"Hamburg"}];

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>{config.appName}</Typography>
      </div>
      <div className={classes.formContainer} >
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={t("Login")} classes={{ root: classes.tab }} />
            <Tab label={t("New User")} classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                {config.appName}
              </Typography>
              <Typography variant="h2" className={classes.subGreeting}>
              {t("Login to your account")}
              </Typography>
              { 1===0 &&
                <div>
                  <Button size="large" className={classes.googleButton}>
                    <img src={google} alt="google" className={classes.googleIcon} />
                    &nbsp;{t("Sign in with Google")}
                  </Button>
                  <div className={classes.formDividerContainer}>
                    <div className={classes.formDivider} />
                    <Typography className={classes.formDividerWord}>{t("or")}</Typography>
                    <div className={classes.formDivider} />
                  </div>
                </div>
              }
              <Fade in={userState.error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {t(userState.message)}
                </Typography>
              </Fade>
              <Fade in={userState.success}>
                <Typography color="primary" className={classes.errorMessage}>
                  {t(userState.message)}
                </Typography>
              </Fade>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder={t("E-mail Adress")}
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder={t("Password")}
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {t("Login")}
                  </Button>
                )}
                <Button
                  disabled={
                    loginValue.length === 0 
                  }
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={() =>
                    resetUserPwd(
                      userDispatch,
                      loginValue,
                      setIsLoading,
                    )
                  }
                >
                  {t("Forget Password")}
                </Button>
              </div>
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                {t("Welcome!")}
              </Typography>
              <Typography variant="h2" className={classes.subGreeting}>
              {t("Create your account")}
              </Typography>
              <TextField
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={nameValue}
                onChange={e => setNameValue(e.target.value)}
                margin="normal"
                placeholder={t("Name")}
                type="text"
                fullWidth
              />
              <TextField
                id="surname"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={surnameValue}
                onChange={e => setSurnameValue(e.target.value)}
                margin="normal"
                placeholder={t("Surname")}
                type="text"
                fullWidth
              />
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder={t("Email")}
                type="email"
                fullWidth
              />
              <TextField
                id="birthdate"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={birthdateValue}
                onChange={e => setBirthdateValue(e.target.value)}
                margin="normal"
                placeholder={t("Birthdate")}
                type="date"
                fullWidth
              />
              <TextField
                id="adress"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={adressValue}
                onChange={e => setAdressValue(e.target.value)}
                margin="normal"
                placeholder={t("Adress")}
                type="text"
                fullWidth
              />
              <TextField
                id="phone"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={phoneValue}
                onChange={e => setPhoneValue(e.target.value)}
                margin="normal"
                placeholder={t("Phone")}
                type="number"
                fullWidth
              />
              <Select
                native
                className={classes.selectField}
                value={institueValue}
                onChange={e => setInstitueValue(e.target.value)}
                inputProps={{
                  name: 'institue_id',
                  id: 'institue-select',
                }}
              >
                <option key={'institue_option_0'} value="0" >{t("Institue")}</option>
                {institues.map(inst => (
                  <option key={'institue_option_' + inst.id} value={inst.id}>{inst.name}</option>
                ))}
              </Select>
              <Select
                native
                className={classes.selectField}
                value={roleValue}
                onChange={e => setRoleValue(e.target.value)}
                inputProps={{
                  name: 'role_id',
                  id: 'role-select',
                }}
              >
                <option key={'role_option_0'} value="0" >{t("Role")}</option>
                <option key={'role_option_1'} value="3" >{t("Student")}</option>
                <option key={'role_option_2'} value="5" >{t("Parent")}</option>
              </Select>
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={() =>
                      registerUser(
                        userDispatch,
                        nameValue,
                        surnameValue,
                        loginValue,
                        phoneValue,
                        adressValue,
                        birthdateValue,
                        institueValue,
                        roleValue,
                        setIsLoading,
                      )
                    }
                    disabled={
                      loginValue.length === 0 ||
                      surnameValue.length === 0 ||
                      nameValue.length === 0 ||
                      institueValue == 0 ||
                      adressValue.length === 0 ||
                      phoneValue.length === 0 ||
                      roleValue == 0 ||
                      birthdateValue.length === 0
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    {t("Create your account")}
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2020 - Hamburg {config.appName}
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
