import React, { useEffect, useState} from "react";
import {
  Grid, TextField, CircularProgress, Button, LinearProgress, Select, InputLabel, 
  Chip, FormHelperText, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Tooltip,
  IconButton,
} from "@material-ui/core";
import { Edit as EditIcon, FileCopy as CopyIcon, Delete as DeleteIcon } from "@material-ui/icons";
import {Autocomplete} from '@material-ui/lab';
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {sortBy} from "../../helper";

export default function ProgramEdit(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 
  // global
  var userState = useUserState();
  const user = userState.user;

  //props
  const { iid, id } = props.match.params;

  // local
  const [programData, setProgramData] = useState({"institue_id":"0","program_title":"","program_desc":"","program_dates":""});
  const [courses, setCourses] = useState([]);
  const [institues, setInstitues] = useState([]);
  const [institueGroups, setInstitueGroups] = useState([]);
  const [institueGroupFilter, setInstitueGroupFilter] = useState("0");

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(){

    let responses = await Promise.all([
      genericFetch(config.apiURL + '/records/institues', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data => setInstitues(data.records)),
      genericFetch(config.apiURL + '/records/institue_groups/', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data => setInstitueGroups(data.records)),
    ])

    id>0 && genericFetch(config.apiURL + '/records/programs/'+id, {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(data => setProgramData(data));
    
    id>0 && genericFetch(config.apiURL + '/records/courses?filter=program_id,eq,'+id+'&join=institue_groups&join=texts,topics&join=users', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(data => setCourses(data.records));

    return responses;
  }
  
  async function saveData(){   
    let url,fetchConfig;

    if(programData._id === undefined){
      url = '/records/programs';
      fetchConfig = config.saveConfig;
    }else{
      url = '/records/programs/'+programData._id; 
      fetchConfig = config.updateConfig;   
    }

    let response = await genericFetch(config.apiURL + url, {...fetchConfig, body:JSON.stringify(programData) }, 
        t("Program successfully saved!"), setIsLoading, setErr, setMsg);
    
    return response;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();  
    await saveData();
  }

  const handleProgramChange = (e) => {
    const obj = { ...programData, [e.target.name]:e.target.value};
    setProgramData(obj);
  }

  const handleDatesChange = (event, values) => {
    let obj = { ...programData, "program_dates":values.join(","), "user_id": user._id};
    setProgramData(obj);
  }

  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }

  const openText = (e, id) => {
    e.preventDefault();  
    props.history.push("/app/institueAdmin/texts/edit/"+id);
  }

  const editCourse = (e, id) => {
    e.preventDefault();  
    props.history.push("/app/institueAdmin/programs/"+programData._id+"/edit/"+id);
  }

  async function deleteData(_id){   
    let url = '/records/courses/'+ _id;    
    let response = await 
    genericFetch(config.apiURL + url, {...config.deleteConfig}, t("Course successfully deleted!"), setIsLoading, setErr, setMsg);
    return response;
  }

  const deleteCourse= (e, _id) => {
    e.preventDefault();
    if (window.confirm(t('Are you sure you wish to delete this?'))){
      deleteData(_id);   
    }
  }

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }   
      {isLoading &&  <LinearProgress />}
      <PageTitle title={t("Program Management")} button={{title:t('New Course'), link:"#/app/institueAdmin/programs/"+programData._id+"/edit/-1", disabled:(programData._id)?false:true} }/>    
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title={t("Program Info" )} disableWidgetMenu>
            <div className={classes.formContainer}>
              <div className={classes.form}>                  
                <form className={classes.root} noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
                  <div>
                    <InputLabel shrink htmlFor="institue-select">{t("Institue")}</InputLabel>
                    <Select
                      native
                      className={classes.selectField}
                      value={programData.institue_id}
                      onChange={e => handleProgramChange(e)}
                      inputProps={{
                        name: 'institue_id',
                        id: 'institue-select',
                      }}
                    >
                      <option key="0" value="0" >{t("Please select")}</option>
                      {institues.map(institue => (
                        <option key={institue._id} value={institue._id}>{institue.institue_name}</option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <InputLabel shrink htmlFor="year-select">{t("Program Year")}</InputLabel>
                    <Select
                      native
                      className={classes.selectField}
                      value={programData.program_year}
                      onChange={e => handleProgramChange(e)}
                      inputProps={{
                        name: 'program_year',
                        id: 'year-select',
                      }}
                    >
                      <option key="0" value="0" >{t("Please select")}</option>
                      {[2020,2021,2022,2023,2024].map(year => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <TextField name="program_title" label={t("Title")} type="text" className={classes.textField} 
                                value={programData.program_title}
                                onChange={e => handleProgramChange(e)}/>
                  </div>
                  <div>
                    <TextField name="program_desc" label={t("Description")} type="text" className={classes.textField} 
                                value={programData.program_desc}
                                onChange={e => handleProgramChange(e)}/>
                  </div>
                  <div>
                    <Autocomplete
                      multiple
                      freeSolo
                      id="program_dates"
                      options={[]}
                      value={programData.program_dates.split (',').filter(function(el) {return el.length !== 0})}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} name="program_dates-input" label={t("Dates")} type="date" />
                      )}
                      onChange={handleDatesChange}
                    />
                    <FormHelperText>{t("Select date and hit enter")}</FormHelperText>
                  </div>


                  <div className={classes.formButtons}>
                  {isLoading ? (
                      <CircularProgress size={26} className={classes.loginLoader} />
                    ) : (
                    <Button
                      disabled={
                        (programData.program_dates.length === 0 || programData.program_title.length === 0) ? true : false
                      }
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("Save")}
                    </Button>
                    )}
                    <Button
                      color="secondary"
                      size="large"
                      className={classes.cancelButton}
                      onClick={e => goBack(e)}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Widget>
        </Grid>
        
        {(programData._id) ? (
        <>
          <Grid item xs={12}>
            <Widget disableWidgetMenu>
              <div>
                <InputLabel htmlFor="institue-group-select">{t("Group")}</InputLabel>
                <Select
                  native
                  className={classes.selectField}
                  value={institueGroupFilter}
                  onChange={e => setInstitueGroupFilter(e.target.value)}
                  inputProps={{
                    name: 'institue_group_id',
                    id: 'institue-group-select',
                  }}
                >
                  <option value="0" >{t("All Groups")}</option>
                  {institueGroups.map(institueGroup => (
                    <option key={institueGroup._id} value={institueGroup._id}>{institueGroup.group_name}</option>
                  ))}
                </Select>
              </div>
            </Widget>
          </Grid>

          {courses.filter(course => !programData.program_dates.includes(course.course_date)).length>0 &&
          <Grid item xs={12}>
            <Widget title={t("Unscheduled Courses")} disableWidgetMenu>
              <List className={classes.root} component="nav">
                {courses
                  .filter(course => !programData.program_dates.includes(course.course_date) && (institueGroupFilter=="0" || course.institue_group_id._id == institueGroupFilter ))
                  .sort(sortBy("start_time"))
                  .map((course,cindex) =>
                  [
                    <ListItem key={cindex} button alignItems="flex-start"  onClick={e => editCourse(e,course._id)} >
                      <Grid container alignItems="center" >
                        <Typography className={classes.inline} color="textPrimary">
                          {course.start_time.toString().substring(0,5)+"-"+course.end_time.toString().substring(0,5)}
                        </Typography>  
                        <Divider orientation="vertical" flexItem className={classes.divider}/>
                        <Grid item>
                          <ListItemText
                            primary={course.institue_group_id.group_name + " - " + course.text_id.topic_id.topic_name}
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  {course.text_id.title}
                                </Typography>                              
                              </>
                            }
                          />
                          <ListItemText secondary={course.teacher_id.name+" "+course.teacher_id.surname} />
                          <ListItemText secondary={course.course_date} />
                        </Grid>
                      </Grid>
                      <ListItemSecondaryAction>
                        <Tooltip key={2} title={t("Edit")}>
                          <IconButton onClick={e => editCourse(e,course._id)} color="primary">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip key={2} title={t("Delete")}>
                          <IconButton onClick={e => deleteCourse(e,course._id)} color="secondary">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>,
                    <Divider key={cindex+1000} variant="inset" component="li" />
                  ]
                )}
              </List>
            </Widget>
          </Grid>
          }

          {programData.program_dates
            .split(",")
            .sort(sortBy("program_date"))
            .map((program_date,pindex)=>
              <Grid key={pindex} item xs={12} sm={6}>
                <Widget title={programData.program_title +" - "+ program_date} disableWidgetMenu>
                  <List className={classes.root} component="nav">
                    {courses
                      .filter(course => course.course_date === program_date && (institueGroupFilter=="0" || course.institue_group_id._id == institueGroupFilter ))
                      .sort(sortBy("start_time"))
                      .map((course,cindex) =>
                      [
                        <ListItem key={cindex} button alignItems="flex-start" onClick={e => editCourse(e,course._id)} >
                          <Grid container alignItems="center" >
                            <Typography className={classes.inline} color="textPrimary">
                              {course.start_time.toString().substring(0,5)+"-"+course.end_time.toString().substring(0,5)}
                            </Typography>  
                            <Divider orientation="vertical" flexItem className={classes.divider}/>
                            <Grid item>
                              <ListItemText
                                primary={course.institue_group_id.group_name + " - " + course.text_id.topic_id.topic_name}
                                secondary={
                                  <>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      className={classes.inline}
                                      color="textPrimary"
                                    >
                                      {course.text_id.title}
                                    </Typography>                              
                                  </>
                                }
                              />
                              <ListItemText secondary={course.teacher_id.name+" "+course.teacher_id.surname} />
                            </Grid>
                          </Grid>
                          <ListItemSecondaryAction>
                            <Tooltip key={2} title={t("Edit")}>
                              <IconButton onClick={e => editCourse(e,course._id)} color="primary">
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip key={2} title={t("Delete")}>
                              <IconButton onClick={e => deleteCourse(e,course._id)} color="secondary">
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>,
                        <Divider key={cindex+1000} variant="inset" component="li" />
                      ]
                    )}
                  </List>
                </Widget>
              </Grid>
            )}

            

          </>
          ):(
          <Grid item xs>
            <Typography variant="h4" className={classes.text}>
              {t("Please save program to add courses!...")}
            </Typography>
          </Grid>
          )}
        </Grid>
    </>
  );
}
