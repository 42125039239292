import React, { useEffect, useState } from "react";
import {
  Grid, TextField, CircularProgress, Button, IconButton, 
  Select, InputLabel, Avatar,List, ListItem, ListItemText, 
  ListItemSecondaryAction, Switch, LinearProgress
} from "@material-ui/core";
import {
  AccountCircle as AccountCircleIcon,
} from "@material-ui/icons";

//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Toaster from "../../components/Toaster/Toaster";
import { Typography } from "../../components/Wrappers/Wrappers";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {UUID} from "../../helper";


export default function UserConfirm(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // local
  const [userData, setUserData] = useState({"institue_id":0,"role_id":0,"name":"","surname":"","phone":"","email":"","birthdate":"","password":"","address":"","institue_group_users":[]});
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [institues, setInstitues] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [avatar, setAvatar] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);

  const { id } = props.match.params;


  const getGroupsJson = (userid) => {
    var ugroups = [];
    checkedGroups.forEach(group => {
      ugroups.push({"institue_group_id":group,"user_id":userid})
    })
    return ugroups;
  }
  
  async function fetchData(){  
    let responses = await Promise.all([
      genericFetch(config.apiURL + '/records/roles', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data => setRoles(data.records)),
      genericFetch(config.apiURL + '/records/institues', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data => setInstitues(data.records)),
      genericFetch(config.apiURL + '/records/institue_groups', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data => setGroups(data.records)),
    ])

    id>0 && await genericFetch(config.apiURL + '/records/registered_users/'+id+'?exclude=_id', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(userRegister => {
        setUserData({...userData, ...userRegister}); 
      })

    return responses;
  }

  async function saveData(){   
    let urluser,urlgroup, urltmp;
    urluser = '/records/users';
    urlgroup = '/records/institue_group_users';
    urltmp = '/records/registered_users/'+id;

    avatar.data && await saveAvatar();

    let userid = await genericFetch(config.apiURL + urluser, {...config.saveConfig, body:JSON.stringify(userData) }, t('User successfully saved!'), setIsLoading, setErr, setMsg)
    
    if(userid>0 && checkedGroups.length>0) 
      await genericFetch(config.apiURL + urlgroup, {...config.saveConfig, body: JSON.stringify(getGroupsJson(userid))}, null, setIsLoading, setErr, setMsg)
    
    await genericFetch(config.apiURL + urltmp, {...config.deleteConfig}, null, setIsLoading, setErr, setMsg);

    // send password set email
    await fetch(config.registerapiURL + 'req=confirm&lang=tr', {...config.saveConfig,body:JSON.stringify({"email": userData.email})});

    return userid;
  }


  async function saveAvatar(){   
    const formData  = new FormData();
    formData.append('file_base64', avatar.data);
    formData.append('filename', avatar.uid);

    let response = 
      await genericFetch(config.imageapiURL + avatar.uid, {...config.fileSaveConfig, body: formData}, null, setIsLoading, setErr, setMsg);

    setAvatar({});
    return response;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();  
    saveData();
  }

  const handleImage = ({ target }) => {
    if(target.files[0]){
      const file = target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
          const name = file.name;
          const type = name.split(".").pop();
          const uid = UUID() + "." +type;
          setAvatar({"uid":uid,"data":e.target.result});
          setUserData({...userData,"avatar_url":uid});   
      }
    };
  };

  const handleChange = (e) => {
    let obj = { ...userData, [e.target.name]:e.target.value};
    setUserData(obj);
  }

  const handleToggle = (value) => () => {
    const currentIndex = checkedGroups.indexOf(value);
    const newChecked = [...checkedGroups];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedGroups(newChecked);
  };

  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }


  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }   
      {isLoading &&  <LinearProgress />}
      <PageTitle title={t("User Management")} />      
      <Grid container spacing={1}>
        <Grid item xs={12} sm={10} md={10} lg={8} xl={6}>
          <Widget title={t("User Info" )} disableWidgetMenu>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
            <>
            <Grid container spacing={2} direction="column" alignItems="center">
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleImage}
              />
              <label htmlFor="raised-button-file">
                <IconButton component="span">
                  { avatar.data ? <Avatar key={1} alt={userData.name} src={avatar.data}  className={classes.bigAvatar}/>
                  : 
                    [ userData.avatar_url ? <Avatar key={2} alt={userData.name} src={config.imageapiURL + userData.avatar_url}  className={classes.bigAvatar}/>
                    :
                      <Avatar key={3} className={classes.bigAvatar}>
                        <AccountCircleIcon className={classes.bigAvatar}/>
                      </Avatar>
                    ]}
                </IconButton>
              </label> 
              <Typography component="h5" variant="h5">
                {userData.name+" "+userData.surname}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {userData.email}
              </Typography>
            </Grid>

            <Grid item xs={12}>
            <div className={classes.formContainer}>
              <div className={classes.form}>
                <form className={classes.root} noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>  
                  <div className={classes.formButtons}>
                    <InputLabel htmlFor="institue-select">{t("Status")}</InputLabel>
                    <div>
                      {userData.user_status ? t("Active") : t("Passive") }
                      <Switch
                          edge="end"
                          name="user_status"
                          onChange={()=>setUserData({...userData,"user_status":!userData.user_status})}
                          checked={userData.user_status>0}
                          color="primary"
                        />
                    </div>
                  </div>
                  <div>
                    <InputLabel htmlFor="institue-select">{t("Institue")}</InputLabel>
                    <Select
                      native
                      className={classes.selectField}
                      value={userData.institue_id}
                      onChange={e => handleChange(e)}
                      inputProps={{
                        name: 'institue_id',
                        id: 'institue-select',
                      }}
                    >
                      <option key="0" value="0" >{t("Please select")}</option>
                      {institues.map(institue => (
                        <option key={institue._id} value={institue._id}>{institue.institue_name}</option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <InputLabel htmlFor="role-select">{t("Role")}</InputLabel>
                    <Select
                      native
                      className={classes.selectField}
                      value={userData.role_id}
                      onChange={e => handleChange(e)}
                      inputProps={{
                        name: 'role_id',
                        id: 'role-select',
                      }}
                    >
                      <option key="0" value="0" >{t("Please select")}</option>
                      {roles.map(role => (
                        <option key={role._id} value={role._id}>{t(role.role_name)}</option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <TextField name="name" label={t("Name")} type="text" className={classes.textField} 
                                value={userData.name}
                                onChange={e => handleChange(e)}/>
                  </div>
                  
                  <div>
                    <TextField name="surname" label={t("Surname")} type="text" className={classes.textField} 
                              value={userData.surname}
                              onChange={e => handleChange(e)}/>
                  </div>
                  <div>
                    <TextField name="email" label={t("Email")} type="email" className={classes.textField} 
                              value={userData.email}
                              onChange={e => handleChange(e)}/>
                  </div>
                  <div>
                    <TextField name="phone" label={t("Phone")} type="number"  className={classes.textField} 
                              value={userData.phone}
                              onChange={e => handleChange(e)}/>
                  </div>
                  <div>
                    <TextField name="birthdate" label={t("Birthday")} type="date" className={classes.textField} 
                              value={userData.birthdate}
                              onChange={e => handleChange(e)}/>
                  </div>
                  <div>
                    <TextField name="address" label={t("Address")} type="text" className={classes.textField} 
                              value={userData.adress}
                              onChange={e => handleChange(e)}/>
                  </div>
                  <div>
                    <InputLabel htmlFor="group-select">{t("Groups")}</InputLabel>
                    <List dense={true}>
                      {groups.map(group => 
                        <ListItem key={group._id}>
                          <ListItemText
                            primary={group.group_name}
                          />
                          <ListItemSecondaryAction>
                            <Switch
                              edge="end"
                              onChange={handleToggle(group._id)}
                              checked={checkedGroups.indexOf(group._id) !== -1}
                              inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      )}
                    </List>
                  </div>


                  <div className={classes.formButtons}>
                    <Button
                      disabled={
                        (userData.role_id === 0 || userData.institue_id === 0 || userData.email.length === 0 || userData.birthdate.length === 0 ) ? true : false
                      }
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("Save")}
                    </Button>
                    <Button
                      color="secondary"
                      size="large"
                      className={classes.cancelButton}
                      onClick={e => goBack(e)}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>      
                </form>
              </div>
            </div>
            </Grid>
            </>
            )}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
