import React, { useEffect, useState } from "react";
import {
  Grid, TextField, CircularProgress, Button, Select, InputLabel, 
  List, ListItem, ListItemText, ListItemSecondaryAction, Switch,
} from "@material-ui/core";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";


export default function UserEdit(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // local
  const [groupData, setGroupData] = useState({"institue_id":"0","group_name":"","group_desc":""});
  const [institues, setInstitues] = useState([]);
  const [users, setUsers] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  const { id } = props.match.params;

  const getUsersJson = (groupid) => {
    var ugroups = [];
    checkedUsers.forEach(userid => {
      ugroups.push({"institue_group_id":groupid,"user_id":userid})
    })
    return ugroups;
  }

  async function fetchData(){

    let responses = await Promise.all([
      genericFetch(config.apiURL + '/records/institues', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data=>setInstitues(data.records)),
      genericFetch(config.apiURL + '/records/users', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
        .then(data=>setUsers(data.records)),
    ])

    id>0 && genericFetch(config.apiURL + '/records/institue_groups/'+id+'?join=institue_group_users', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
      .then(data=>{
        setGroupData(data);
        setCheckedUsers(data.institue_group_users.map(group=> group.user_id));
      });

    return responses;
  }

  async function saveData(){   
    let url = '/records/institue_groups';
    let urlgroup = '/records/institue_group_users';

    let groupid = await genericFetch(config.apiURL + url, {...config.saveConfig, body:JSON.stringify(groupData) }, 
        t("Group successfully saved!"), setIsLoading, setErr, setMsg)

    if(checkedUsers.length>0 && groupid>0) 
      await genericFetch(config.apiURL + urlgroup, {...config.saveConfig, body:JSON.stringify(getUsersJson(groupid)) }, 
        t("Group users successfully saved!"), setIsLoading, setErr, setMsg)

    return groupid;
  }

  async function updateData(){   
    let url = '/records/institue_groups/'+groupData._id;
    let urldelgroup = '/records/institue_group_users/'+groupData.institue_group_users.map(gp => gp._id).join(','); 
    let urlgroup = '/records/institue_group_users/';

    let response = await genericFetch(config.apiURL + url, {...config.updateConfig, body:JSON.stringify(groupData) }, 
        t("Group successfully saved!"), setIsLoading, setErr, setMsg);

    if(groupData.institue_group_users.length>0) 
      await genericFetch(config.apiURL + urldelgroup, {...config.deleteConfig }, null, setIsLoading, setErr, setMsg);

    if(checkedUsers.length>0) 
      await genericFetch(config.apiURL + urlgroup, {...config.saveConfig, body:JSON.stringify(getUsersJson(groupData._id)) }, 
        t("Group users successfully saved!"), setIsLoading, setErr, setMsg)

    return response;
  }


  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault(); 
    if(groupData._id === undefined){
      saveData();
    }else{
      updateData();
    }
  }

  const handleChange = (e) => {
    let obj = { ...groupData, [e.target.name]:e.target.value};
    setGroupData(obj);
  }

  const handleToggle = (value) => () => {
    const currentIndex = checkedUsers.indexOf(value);
    const newChecked = [...checkedUsers];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedUsers(newChecked);
  };

  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }


  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }   
      <PageTitle title={t("Group Management")} /> 
      <Grid item xs={12} md={9}>
        <Widget title={t("Group Info" )} disableWidgetMenu>
          <div className={classes.formContainer}>
            <div className={classes.form}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <>
                <form className={classes.root} noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
                  <div>
                    <InputLabel htmlFor="institue-select">{t("Institue")}</InputLabel>
                    <Select
                      native
                      className={classes.selectField}
                      value={groupData.institue_id}
                      onChange={e => handleChange(e)}
                      inputProps={{
                        name: 'institue_id',
                        id: 'institue-select',
                      }}
                    >
                      <option key="0" value="0" >{t("Please select")}</option>
                      {institues.map(institue => (
                        <option key={institue._id} value={institue._id}>{institue.institue_name}</option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <TextField name="group_name" label={t("Name")} type="text" className={classes.textField} 
                                value={groupData.group_name}
                                onChange={e => handleChange(e)}/>
                  </div>
                  
                  <div>
                    <TextField name="group_desc" label={t("Description")} type="text" className={classes.textField} 
                              value={groupData.group_desc}
                              onChange={e => handleChange(e)}/>
                  </div>

                  <div>
                    <InputLabel htmlFor="group-select">{t("Groups")}</InputLabel>
                    <List dense={true}>
                      {users.map(user => 
                        <ListItem key={user._id}>
                          <ListItemText
                            primary={user.name+" "+user.surname}
                          />
                          <ListItemSecondaryAction>
                            <Switch
                              edge="end"
                              onChange={handleToggle(user._id)}
                              checked={checkedUsers.indexOf(user._id) !== -1}
                              inputProps={{ 'aria-labelledby': 'switch-list-label' }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      )}
                    </List>
                  </div>

                  <div className={classes.formButtons}>
                    <Button
                      disabled={
                        (groupData && groupData.name && groupData.name.length === 0) ? true : false
                      }
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("Save")}
                    </Button>
                    <Button
                      color="secondary"
                      size="large"
                      className={classes.cancelButton}
                      onClick={e => goBack(e)}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </>
            )}
            </div>
          </div>
        </Widget>
      </Grid>
    </>
  );
}
