const config = process.env.NODE_ENV === 'production' ? {
    appName: "E-Rehberim",
    apiURL: "/api",
    imageapiURL: "/imageapi/",
    registerapiURL: "/register/",
    domain:"'e-rehberim.com'",
    loginConfig: {
      method: 'POST',
      redirect: 'follow', 
      credentials: 'include',
      headers: {'Content-Type':'application/json'}
    },
    fetchConfig: {
      method: 'GET',
      redirect: 'follow', 
      credentials: 'include', 
      headers: {'Content-Type':'application/json'}
    },
    saveConfig: {
      method: 'POST',
      redirect: 'follow', 
      credentials: 'include', 
      headers: {'Content-Type':'application/json'}
    },
    fileSaveConfig: {
      method: 'POST',
      redirect: 'follow', 
      credentials: 'include'
    },
    updateConfig: {
      method: 'POST',
      redirect: 'follow', 
      credentials: 'include', 
      headers: {'Content-Type':'application/json',"x-request-method":"PUT"}
    },
    deleteConfig: {
      method: 'GET',
      redirect: 'follow', 
      credentials: 'include', 
      headers: {'Content-Type':'application/json',"x-request-method":"DELETE"}
    },
    imageConfig: {
      method: 'GET',
      redirect: 'follow',
      credentials: 'include',  
      headers: {'Content-Type':'image/png'}
    },
    patchConfig: {
      method: 'POST',
      redirect: 'follow',
      credentials: 'include',  
      headers: {'Content-Type':'application/json',"x-request-method":"PATCH"}
    },
    roles: {admin:1,institue_admin:2,student:3,teacher:4,parent:5}
  } : {
    appName: "E-Rehberim",
    domain:"e-rehberim.com",
    apiURL: "http://localhost:8080/eduapp_server/api.php",
    //apiURL:"http://192.168.64.2/eduapp_server/api.php",
    imageapiURL: "http://localhost:8080/eduapp_server/imageapi.php/",
    registerapiURL: "http://localhost:8080/eduapp_server/register.php?",
    loginConfig: {
      method: 'POST',
      redirect: 'follow',
      credentials: 'include', 
      headers: {'Content-Type': 'application/json',}
    },
    fetchConfig: {
      method: 'GET',
      redirect: 'follow',
      credentials: 'include',  
      headers: {'Content-Type':'application/json'}
    },
    saveConfig: {
      method: 'POST',
      redirect: 'follow',
      credentials: 'include', 
      headers: {'Content-Type':'application/json',}
    },
    fileSaveConfig: {
      method: 'POST',
      redirect: 'follow', 
      credentials: 'include'
    },
    updateConfig: {
      method: 'PUT',
      redirect: 'follow',
      credentials: 'include', 
      headers: {'Content-Type':'application/json'}
    },
    deleteConfig: {
      method: 'DELETE',
      redirect: 'follow',
      credentials: 'include', 
      headers: {'Content-Type':'application/json'}
    },
    imageConfig: {
      method: 'GET',
      redirect: 'follow',
      credentials: 'include',  
      headers: {'Content-Type':'image/png'}
    },
    patchConfig: {
      method: 'PATCH',
      redirect: 'follow',
      credentials: 'include',  
      headers: {'Content-Type':'application/json'} 
    },
    roles: {admin:1,institue_admin:2,student:3,teacher:4,parent:5}
  }

export default config;
