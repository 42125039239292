import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Button
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Message as MessageIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
  Language as LanguageIcon
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography } from "../Wrappers/Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import { useUserState, useUserDispatch, signOut } from "../../context/UserContext";
import { useTranslation } from 'react-i18next';

import config from "../../config"



const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" }
];

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  var userState = useUserState();
  var { t, i18n } = useTranslation(); 

  // local
  var [languageMenu, setLanguageMenu] = useState(null);
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);

  const user = userState.user;
  const urmessages = userState.messages;
  const langs = i18n.options.langs;

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    setLanguageMenu(null);
  };


  const gotoMessages = () => {
    props.history.push("/app/mymessages");
  } 
  
  const handleMessageClick = (e) => {
    setMailMenu(e.currentTarget);
    setIsMailsUnread(false);
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          {t(config.appName)}
        </Typography>     


        
        <div className={classes.grow} /> 
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            placeholder={t("Search…")}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div>  
        <div className={classes.profileMenuUserBar}>
          <Typography className={classes.text} >
                {user ? user.name+" "+user.surname : "" } 
          </Typography>  
          <Typography className={classes.text} size="sm">
                {user ? t(user.role_id.role_name) : "" } 
          </Typography>  
        </div>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        {1===0 &&
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="notifications-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isNotificationsUnread ? notifications.length : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        }
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {handleMessageClick(e)}}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isMailsUnread && urmessages ? urmessages.map(msg=>msg.unread_messages).reduce((acc,cur)=> acc + cur): null}
            color="secondary"
          >
            <MessageIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <Button
          aria-controls="language-menu"
          aria-haspopup="true"
          className={classes.headerButton}
          onClick={e => {
            setLanguageMenu(e.currentTarget);
          }}
          startIcon={<LanguageIcon />}
        >
          {i18n.language.toUpperCase()}
        </Button> 
        <Menu
          id="language-menu"
          anchorEl={languageMenu}
          open={Boolean(languageMenu)}
          onClose={() => setLanguageMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {langs.map(language => (
            <MenuItem key={language.id} selected={language.id === i18n.language} onClick={() => changeLanguage(language.id)}>
              {language.name}
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {t("New Messages")}
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {urmessages && urmessages.map(msg=>msg.unread_messages).reduce((acc,cur)=> acc + cur)} {t("New Messages")}
            </Typography>
          </div>
          {urmessages && urmessages.map(mgr => (
            <MenuItem key={mgr._id} className={classes.messageNotification} onClick={gotoMessages}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color="primary" name={mgr.group_name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {mgr.last_message_date}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {mgr.group_name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {mgr.last_message_date}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
            onClick={gotoMessages}
          >
            {t("Send New Message")}
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {user ? user.name+" "+user.surname : "" } 
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
              href="#"
            >
              {user ? user.email : ""}
            </Typography>
          </div>
          <MenuItem
            onClick={() => props.history.push("/app/myprofile")}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> {t("My Profile")}
          </MenuItem>
          <MenuItem
            onClick={() => props.history.push("/app/mynotifications")}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> {t("Notifications")}
          </MenuItem>
          <MenuItem
            onClick={() => props.history.push("/app/mymessages")}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> {t("My Messages")}
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              {t("Sign Out")}
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
