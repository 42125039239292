import React, { useEffect, useState } from "react";
import {
  Grid, LinearProgress, IconButton, Tooltip, Switch
} from "@material-ui/core";
import { Edit as EditIcon, VerifiedUser as VerifyIcon, Delete as DeleteIcon } from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import MUIDataTable from "mui-datatables";
import Toaster from "../../components/Toaster";

// config
import config from "../../config";

// context
import { genericFetch } from "../../context/FetchContext";


export default function UserManagement(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // local
  var [datatableData, setDatatableData] = useState([]);
  var [tempUsers, setTempUsers] = useState([]);
  var [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);

  const tempUsersColumns =[
    {
      label:t('E-mail'),
      name: "email"
    },
    {
      label: t('Name'),
      name: "name"
    },
    {
      label:t('Surname'),
      name: "surname"
    },
    {
      name: "",
      options: {
        filter:false,sort:false,print:false,download:false,viewColumns:false,
        customBodyRender: (value, tableMeta, updateValue) => (
          [<Tooltip key={1} title={t("Confirm User")}>
            <IconButton onClick={e => confirmTempUser(e,value)} color="primary">
              <VerifyIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip key={2} title={t("Reject Registration")}>
            <IconButton onClick={e => deleteRegistration(e,value)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Tooltip>]
        )
      }
    }
  ];

  const columns = [
    {
      label:t('Institue'),
      name: "institue"
    },
    {
      label: t('Name'),
      name: "name"
    },
    {
      label:t('Role'),
      name: "role"
    },
    {
      label:t('Status'),
      name: "status",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>
                <Typography color={value<=0 && "secondary" }>
                  {t("Passive")}
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  name="status"
                  checked={value>0}
                  color="primary"
                />
              </Grid>
              <Grid item>
                <Typography color={value>0 && "primary" }>
                  {t("Active")}
                </Typography>
              </Grid>
            </Grid>
          );
        }
      }
    },
    {
      name: "",
      options: {
        filter:false,sort:false,print:false,download:false,viewColumns:false,
        customBodyRender: (value, tableMeta, updateValue) => (
          [<Tooltip key={1} title={t("Edit")}>
            <IconButton onClick={e => editUser(e,value)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip key={2} title={t("Delete")}>
            <IconButton onClick={e => deleteUser(e,value)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Tooltip>]
        )
      }
    }
  ];

  

  async function fetchData(){

    genericFetch(config.apiURL + '/records/registered_users', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
    .then(tmp_users=>{
      let resultTempArray = [];
      for (let i = 0; i < tmp_users.records.length; i++) {
        resultTempArray.push([
          tmp_users.records[i].email,
          tmp_users.records[i].name,
          tmp_users.records[i].surname,
          tmp_users.records[i]._id
        ]);
      }
      setTempUsers(resultTempArray);  
    });

    genericFetch(config.apiURL + '/records/users?join=roles&join=institues', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg)
    .then(response=>{
      let resultArray = [];
      for (let i = 0; i < response.records.length; i++) {
        resultArray.push([
          response.records[i].institue_id.institue_name,
          response.records[i].name +" "+ response.records[i].surname,
          t(response.records[i].role_id.role_name),
          t(response.records[i].user_status),
          response.records[i]._id
        ]);
      }
      setDatatableData(resultArray);  
    });
    
  }

  async function deleteData(_id){   
    let url = '/records/users/'+ _id;   
    let response = await genericFetch(config.apiURL + url, {...config.deleteConfig}, t("User successfully deleted!"), setIsLoading, setErr, setMsg) ;
    response>0 && setDatatableData(datatableData.filter(usr=>usr[4]!==_id));
    return response;
  }

  async function deleteTempUser(_id){   
    let url = '/records/registered_users/'+ _id;   
    let response = await genericFetch(config.apiURL + url, {...config.deleteConfig}, t("Registiration successfully deleted!"), setIsLoading, setErr, setMsg);
    response>0 && setTempUsers(tempUsers.filter(usr=>usr[3]!==_id));
    return response;
  }
  
  const editUser = (e, _id) => {
    e.preventDefault();
    props.history.push("/app/admin/users/edit/"+ _id);
  }

  const deleteUser = (e, _id) => {
    e.preventDefault();
    if (window.confirm(t('Are you sure you wish to delete this?'))){
      deleteData(_id)  
    }
  }


  const confirmTempUser = (e, _id) => {
    e.preventDefault();
    props.history.push("/app/admin/users/confirm/"+ _id);
  }


  const deleteRegistration = (e, _id) => {
    e.preventDefault();
    if (window.confirm(t('Are you sure you wish to delete this?'))){
      deleteTempUser(_id)  
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }  
      {isLoading && <LinearProgress /> }
      <PageTitle title={t('User Management')} button={{title:t('New User'), link:"#/app/admin/users/edit/-1"}} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIDataTable
            title={t('Registration List')}
            data={tempUsers}
            columns={tempUsersColumns}
            options={{
              filterType: "dropdown",
              selectableRows:"none",
              responsive:"scroll"
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIDataTable
            title={t('User List')}
            data={datatableData}
            columns={columns}
            options={{
              filterType: "dropdown",
              selectableRows:"none",
              responsive:"scroll"
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

