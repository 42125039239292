import React, { useState, useEffect } from "react";
import {
  Grid, LinearProgress, List, ListItem, ListItemText, ListItemIcon, Divider, Collapse,
  Card, CardActionArea, CardMedia, CardActions,
} from "@material-ui/core";
import {  Description as DescriptionIcon, Subject as SubjectIcon, ExpandLess, ExpandMore, 
  YouTube as YouTubeIcon, ArrowRight as SubMenuIcon} from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';
import MUIRichTextEditor from "mui-rte";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Toaster from "../../components/Toaster/Toaster";
import CustomDocument from "../../components/Document/Document";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {sortBy} from "../../helper";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";

export default function MyTexts(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const userState = useUserState();
  const user = userState.user;

  //props
  const { textId } = props.match.params;

  // local
  const [textData, setTextData] = useState({});
  const [topicData, setTopicData] = useState({});
  const [documents, setDocuments] = useState([]);
  const [videos, setVideos] = useState([]);
  const [menuIndex, setMenuIndex] = useState({"index":"0","object":{}});
  const [menuStates, setMenuStates] = useState({"documents":false,"videos":false});
  const [filter, setFilter] = useState({"program_id":"0","group_id":"0","program_date":"0"});

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(){

    let data = genericFetch(config.apiURL + '/records/texts/'+textId+'?join=topics', {...config.fetchConfig}, 
      null, setIsLoading, setErr, setMsg)
      .then(data => {
        setTextData(data);
        setTopicData(data.topic_id);
        let docRec = JSON.parse(data.uri);
        let vidRec = JSON.parse(data.url);
        setDocuments(docRec);
        setVideos(vidRec);
      });

    

    return data;
  }



  const handleMenuSelect = (index, object) => {
    setMenuIndex({"index":index,"object":object});
  };

  const handleMenuExpand = (item) => {
    setMenuStates({...menuStates, [item]:!menuStates[item]})
  };



  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }

  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }  
      {isLoading ? <LinearProgress /> :
      <>
        <PageTitle title={t(topicData.topic_name)} />
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Widget title={t("Content")} disableWidgetMenu>
              <div className={classes.root}>
              <List component="nav">
                  <ListItem
                    button
                    selected={menuIndex.index === "0"}
                    onClick={(event) => handleMenuSelect("0",{})}
                    className={[menuIndex.index === "0" && classes.linkIconActive].join(' ')}
                  >
                    <ListItemIcon>
                      <SubjectIcon className={[menuIndex.index === "0" && classes.linkIconActive].join(' ')}/>
                    </ListItemIcon>
                    <ListItemText primary={t("Description")} />
                  </ListItem>
                </List>
                <Divider />
                <List component="nav">
                  <ListItem
                    button
                    onClick={(event) => handleMenuExpand("documents")}
                    className={[menuIndex.index >= 100 && menuIndex.index <200 && classes.linkIconActive].join(' ')}
                  >
                    <ListItemIcon>
                      <DescriptionIcon className={[menuIndex.index >= 100 && menuIndex.index <200 && classes.linkIconActive].join(' ')} />
                    </ListItemIcon>
                    <ListItemText primary={t("Documents")} />
                    {menuStates.documents ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={menuStates.documents} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {documents.map((document,dindex) => 
                      <ListItem key={dindex}
                        button
                        className={[classes.nested, menuIndex.index === dindex+100 && classes.linkIconActive].join(' ')}
                        selected={menuIndex.index === dindex+100}
                        onClick={(event) => handleMenuSelect(dindex+100, document)}
                      >
                        <ListItemIcon>
                          <SubMenuIcon className={[classes.nested, menuIndex.index === dindex+100 && classes.linkIconActive].join(' ')}/>
                        </ListItemIcon>
                        <ListItemText primary={t(document.text)} />
                      </ListItem>
                    )}
                    </List>
                  </Collapse>
                </List>
                <List component="nav">
                  <ListItem
                    button
                    onClick={(event) => handleMenuExpand("videos")}
                    className={[menuIndex.index >=200 && menuIndex.index<300 && classes.linkIconActive].join(' ')}
                  >
                    <ListItemIcon>
                      <YouTubeIcon className={[menuIndex.index >=200 && menuIndex.index<300 && classes.linkIconActive].join(' ')}/>
                    </ListItemIcon>
                    <ListItemText primary={t("Videos")} />
                    {menuStates.videos ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={menuStates.videos} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {videos.map((video,vindex) => 
                      <ListItem key={vindex}
                        button
                        className={[classes.nested, menuIndex.index === vindex+200 && classes.linkIconActive].join(' ')}
                        selected={menuIndex.index === vindex+200}
                        onClick={(event) => handleMenuSelect(vindex+200,video)}
                      >
                        <ListItemIcon>
                          <SubMenuIcon className={[classes.nested, menuIndex.index === vindex+200 && classes.linkIconActive].join(' ')}/>
                        </ListItemIcon>
                        <ListItemText primary={t(video.desc)} />
                      </ListItem>
                    )}
                    </List>
                  </Collapse>
                </List>
              </div>
            </Widget>
          </Grid>


          <Grid item xs={12} sm={12} md={8} lg={9}>
            <Widget title={t(textData.title )} disableWidgetMenu>
              { (menuIndex.index == "0") &&
                <Grid item xs={12} >
                  <div>
                    <MUIRichTextEditor
                      label={t("Description")}
                      value={textData.text}
                      readOnly={true}
                      toolbar={false}
                    />
                  </div>
                </Grid>
              }
              { (menuIndex.index >= 100 && menuIndex.index < 200) &&
                  documents.filter(doc => doc.uid === menuIndex.object.uid).map((doc,index) => (
                    <Grid key={index} item xs={12} >
                      {(doc.mime.includes("image")) ?
                      <Card variant="outlined">
                        <CardActionArea>
                          <CardMedia
                            className={classes.media}
                            image={config.imageapiURL + doc.uid}
                            title={doc.text}
                          />
                        </CardActionArea>
                        <CardActions>
                        </CardActions>
                      </Card>
                      :
                      <CustomDocument url={config.imageapiURL + doc.uid}/>
                      }
                    </Grid>
                  ))}
              { (menuIndex.index >= 200 && menuIndex.index < 300) &&
                <Grid item xs={12} >
                  {videos.filter(vid => vid.uid === menuIndex.object.uid).map((vid) => (
                    <VideoPlayer url={vid.link} />
                  ))}
                </Grid>
              }

              
            </Widget>
          </Grid>
        </Grid>
      </>
      }
    </>
  );
}

