import React, { useState, useEffect } from "react";
import {
  Grid, LinearProgress, CircularProgress, List, ListItem,ListItemText, Tab, Tabs,  
  Button, TextField,  Box, Avatar, ListItemAvatar, Divider, Paper, Badge,
  IconButton, Tooltip, ListSubheader, 
  Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions,
  InputLabel, Select
} from "@material-ui/core";
import { Add as AddIcon} from "@material-ui/icons";
import {Autocomplete,AvatarGroup} from '@material-ui/lab';
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import TabPanel from "../../components/TabPanel/TabPanel";
import { Typography } from "../../components/Wrappers/Wrappers";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState, useUserDispatch, refreshMessages } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {dedupArr} from "../../helper";


export default function MyCourses(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const {user} = useUserState();
  const userDispatch = useUserDispatch();
  //const user = userState.user;
  //const messageGroups = userState.messages;

  // local
  const [myInsGroups, setMyInsGroups] = useState([]);
  const [messageGroups, setMessageGroups] = useState();
  const [messageReceivers, setMessageReceivers] = useState([]);
  const [newMessage, setNewMessage] = useState({});
  const [tabIndex, setTabIndex] = React.useState(0);

  const [isMessageSending, setIsMessageSending] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedInsGroupId, setSelectedInsGroupId] = useState("0");
  const [groupName, setGroupName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentGroupName, setCurrentGroupName] = useState("");
  const [currentGroupUsers, setCurrentGroupUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isTempLoading, setTempIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(){

    let data = await genericFetch(config.apiURL + '/records/message_receivers?filter=receiver_id,eq,'+user._id+'&join=message_groups,messages', {...config.fetchConfig}, 
      null, setIsLoading, setErr, setMsg)
    .then(dt => {
      const mgrps = dt.records.map(mgr=>{return {...mgr.message_group_id,"unread_messages":mgr.unread_messages,"rcv_id":mgr._id}});
      setMessageGroups(mgrps);

      genericFetch(config.apiURL + '/records/message_receivers?filter=message_group_id,in,'+mgrps.map(mg=>mg._id).join(',')+'&join=users', {...config.fetchConfig}, 
        null, setTempIsLoading, setErr, setMsg)
      .then(res => {
        setMessageReceivers(res.records);
        
        mgrps[0] && setCurrentGroupName(mgrps[0].group_name);
        mgrps[0] && setCurrentGroupUsers(res.records.filter(rcv => rcv.message_group_id===mgrps[0]._id).map(mr=>mr.receiver_id));
      });

      const urlfilter = [config.roles.admin,config.roles.institue_admin].includes(user.role_id._id) ? 'filter' :'filter=user_id,eq,'+user._id;
      genericFetch(config.apiURL + '/records/institue_group_users?'+ urlfilter + '&join=institue_groups', {...config.fetchConfig}, 
        null, setTempIsLoading, setErr, setMsg)
      .then(data => {
        const grps = data.records.map(grp => grp.institue_group_id);
        genericFetch(config.apiURL + '/records/institue_groups?filter=_id,in,'+grps.map(grp=>grp._id).join(',')+'&join=institue_group_users,users', {...config.fetchConfig}, 
          null, setTempIsLoading, setErr, setMsg)
        .then(data => {
          setMyInsGroups(data.records);
        });
      });
    });
    
    return data;
  }

  async function sendMessage(gindex, group_id){   
    let msgUrl = '/records/messages'; 
    let msgRcvUrl = '/records/message_receivers/'; 

    let msg = {"message_group_id":group_id,"sender_id":user._id,"message":newMessage[group_id]};

    let newID = await genericFetch(config.apiURL + msgUrl, {...config.saveConfig, body:JSON.stringify(msg)}, t('Message is sent!'), setIsMessageSending, setErr, setMsg);
    
    // increase unread messages for each receiver except user
    const recvGrpIds = messageReceivers.filter(mr => group_id === mr.message_group_id && mr.receiver_id._id !== user._id).map(mr=>{return {"id":mr._id,"body":{"unread_messages":"1"}}});
    genericFetch(config.apiURL + msgRcvUrl +recvGrpIds.map(rcv=>rcv.id).join(","), 
      {...config.patchConfig, body:JSON.stringify(recvGrpIds.map(rcv=>rcv.body))}, null, setIsMessageSending, setErr, setMsg);
    
    setMessageGroups((prevState) => {
      return prevState.map((msggrp,index)=> {
        if(index === gindex){
          return {...msggrp, messages :[...msggrp.messages, {"_id": newID, ...msg} ]}
        }else{
          return msggrp;
        }
      })
    });
    setNewMessage({...newMessage, [group_id]:""})
    return newID;
  }



  async function submitNewGroup() {   

    let groupUrl = '/records/message_groups'; 
    let receiversUrl = '/records/message_receivers'; 
    let grp = {"group_name":groupName,"group_admin_id":user._id};
    let response = await genericFetch(config.apiURL + groupUrl, {...config.saveConfig, body:JSON.stringify(grp)}, null, setIsLoading, setErr, setMsg)
    .then(gid => {
      const grpReceivers = [...selectedUsers.map(usr=>{return{"receiver_id":usr._id,"message_group_id":gid}}),{"receiver_id":user._id,"message_group_id":gid}];
      genericFetch(config.apiURL + receiversUrl, {...config.saveConfig, body:JSON.stringify(grpReceivers)}, null, setIsLoading, setErr, setMsg)
      .then(res => {
        setMessageGroups([...messageGroups,{"_id":gid, "messages":[], ...grp}]);
        setMessageReceivers([...messageReceivers, ...selectedUsers.map(usr=>{return{"receiver_id":usr,"message_group_id":gid}}),{"receiver_id":user,"message_group_id":gid}]);
      });
    });

    return response;
  }



  const handleTabChange = async (event, mgindex) => {
    const mg = messageGroups[mgindex];
    const rcvrs = messageReceivers.filter(mr => mg._id === mr.message_group_id).map(mr=>mr.receiver_id);
    setCurrentGroupUsers(rcvrs);
    setCurrentGroupName(mg.group_name);
    setTabIndex(mgindex);

    // reset my unread_messages
    if(mg.unread_messages>0){
      let receiversUrl = '/records/message_receivers/'; 
      await genericFetch(config.apiURL + receiversUrl + mg.rcv_id, {...config.updateConfig, body:JSON.stringify({"unread_messages":"0"})}, null, setIsMessageSending, setErr, setMsg)
      .then(res => {
        if(res > 0){
          setMessageGroups((prevState) => {
            return prevState.map((msggrp,index)=> {
              if(index === mgindex){
                return {...msggrp, "unread_messages":0}
              }else{
                return msggrp;
              }
            })
          });
          //refreshMessages(user,userDispatch);
        }
      });
    } 
  };


  const handleSubmit = (e, gindex, group_id) => {
    e.preventDefault();  
    sendMessage(gindex, group_id);
  }


  const handleChange = (e, gindex) => {
    let obj ={  ...newMessage, [gindex]:e.target.value};
    setNewMessage(obj);
  }


  const openDialog = (e,setCurrent) => {
    e.preventDefault();  
    if(setCurrent){
      setSelectedUsers(currentGroupUsers.filter(usr=> usr._id!==user._id));
      setGroupName(currentGroupName);
    }else{
      setSelectedUsers([]);
      setGroupName("");
    }
    setDialogOpen(true);
  }

  const createNewGroup = (e) => {
    e.preventDefault();  
    submitNewGroup();
    setDialogOpen(false);
  }

  const handleTagsChange = (event, values) => {
    setSelectedUsers(values);
    values.length===1 && setGroupName(values[0].name+" "+values[0].surname)
  }

  const addAllUsers = () => {
    console.log(myInsGroups);
    console.log(selectedInsGroupId)

    setSelectedUsers(myInsGroups.filter(ig=>ig._id == selectedInsGroupId)
      .flatMap(grp => 
        grp.institue_group_users
          .filter(grpusr => grpusr.user_id._id!==user._id)
          .map(grpusr => grpusr.user_id)));

    setGroupName(myInsGroups.filter(ig=>ig._id == selectedInsGroupId).map(grp=>grp.group_name));
  }

  const closeDialog = () => {
    //console.log(myInsGroups);
    //console.log(myInsGroups.flatMap(grp => grp.institue_group_users.map(grpusr => grpusr.user_id)))
    console.log(dedupArr(myInsGroups.flatMap(grp => grp.institue_group_users.filter(grpusr => grpusr.user_id._id!==user._id).map(grpusr => grpusr.user_id))))

    console.log(currentGroupUsers);

  }


  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg} />}
      {isLoading && <LinearProgress />}
      <PageTitle title={t("My Messages")} />
      {!isLoading && messageGroups &&
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Widget disableWidgetMenu>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                  <Grid container spacing={1} className={classes.chatHeader}>
                    <Typography variant="h3" >
                      {t("Chats")} 
                    </Typography>
                    <Tooltip key={2} title={t("New Chat")}>
                      <IconButton component="span" onClick={e => openDialog(e,false)}>
                        <AddIcon/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Divider className={classes.divider}/>
                  <Paper>
                    <Tabs 
                      value={tabIndex} 
                      onChange={handleTabChange} 
                      indicatorColor="primary"
                      textColor="secondary"
                      orientation="vertical"
                      variant="scrollable"
                      style={{minHeight: 400, overflow: 'auto'}}>
                        {messageGroups.map((mg, gindex) =>
                          <Tab key={gindex} label={<Badge badgeContent={mg.unread_messages} color="primary">{mg.group_name}</Badge>}  />
                        )}
                    </Tabs>
                  </Paper>
                  
                </Grid>
                {currentGroupName &&
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={1} className={classes.chatHeader}>
                    <Button color="primary" onClick={e=>openDialog(e, true)}>
                      {currentGroupName} 
                    </Button>
                    <AvatarGroup max={8}>
                      {currentGroupUsers.map((usr,index) =>
                        <Avatar key={index} alt={usr.name} src={config.imageapiURL + usr.avatar_url} />
                      )}
                    </AvatarGroup>
                  </Grid>
                  <Divider className={classes.divider}/>
                  <Paper>
                    {messageGroups.map((mg, gindex) =>
                      <TabPanel key={gindex} value={tabIndex} index={gindex}> 
                        <Box style={{maxHeight: 400, overflow: 'auto'}}>
                          <List component="nav">
                          {mg.messages.map((msg,mindex) =>
                            (msg.sender_id === user._id)?
                            <ListItem key={mindex} button divider={true} className={classes.listItemRight} autoFocus={mg.messages.length === mindex+1 ? true:false}>
                              <ListItemText
                                primary={
                                  <Typography variant="body1" >
                                    {msg.message}
                                  </Typography>
                                  } 
                                secondary={user.name}
                              />
                              <ListItemAvatar className={classes.avatarRight}>
                                <Avatar src={config.imageapiURL + user.avatar_url}/>
                              </ListItemAvatar>
                            </ListItem>
                            :
                            <ListItem key={mindex} button divider={true} className={classes.listItemLeft} autoFocus={mg.messages.length === mindex+1 ? true:false}>
                              <ListItemAvatar>
                                <Avatar src={config.imageapiURL + messageReceivers.filter(mr => mg._id === mr.message_group_id && msg.sender_id === mr.receiver_id._id).map(mr=>mr.receiver_id.avatar_url).join()}/>
                              </ListItemAvatar>
                              <ListItemText 
                                primary={
                                  <Typography variant="body1">
                                    {msg.message}
                                  </Typography>
                                  } 
                                secondary={messageReceivers.filter(mr => mg._id === mr.message_group_id && msg.sender_id === mr.receiver_id._id).map(mr=>mr.receiver_id.name).join()} 
                              />
                            </ListItem>
                          )}
                          </List>
                        </Box>
                      <div className={classes.formColumnTexts}>
                        <TextField name="message" label={t("Message")} type="text" className={classes.textFieldColumn} 
                                    value={newMessage[mg._id]}
                                    onChange={e => handleChange(e, mg._id)}/>
                        {isMessageSending ? (
                          <CircularProgress size={26} />
                        ) : (            
                        <Button
                          disabled={
                            (newMessage[mg._id]) ? false : true
                          }
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={e => handleSubmit(e, gindex, mg._id)}
                        >
                          {t("Send")}
                        </Button>
                        )}
                      </div>
                      </TabPanel>
                    )}
                  </Paper>
                </Grid>
                }
              </Grid>
            </Widget>
          </Grid>
        <Dialog 
          open={dialogOpen}  
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"sm"}>
          <DialogTitle id="form-dialog-title">{t("New Message Group")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <div className={classes.chatHeader}>
              <Select
                native
                className={classes.selectField}
                value={selectedInsGroupId}
                onChange={e => setSelectedInsGroupId(e.target.value)}
                inputProps={{
                  name: 'institue_group_id',
                  id: 'institue-group-select',
                }}
              >
                <option key="0" value="0" >{t("Student Group")}</option>
                {myInsGroups.map(institueGroup => (
                  <option key={institueGroup._id} value={institueGroup._id}>{institueGroup.group_name}</option>
                ))}
              </Select>
              <Tooltip key={2} title={t("Add All")}>
                <IconButton component="span" onClick={e => addAllUsers(e)}>
                  <AddIcon/>
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Autocomplete
                multiple
                id="selected-users"
                options={dedupArr(myInsGroups.flatMap(grp => grp.institue_group_users
                  .filter(grpusr => grpusr.user_id._id!==user._id)
                  .map(grpusr => grpusr.user_id)))
                }
                getOptionSelected={(option,value)=> { return (option._id===value._id) ? true:false}}
                getOptionLabel={(option) => option.name + " " + option.surname}
                value={selectedUsers}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("To")}
                    placeholder={t("Receivers")}
                  />
                )}
                onChange={handleTagsChange}
              />
            </div>
            <div>
            <TextField name="desc" label={t("Message Group Title")} type="text" 
              autoFocus
              fullWidth
              value={groupName}
              onChange={e => setGroupName(e.target.value)}/>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              {t("Cancel")}
            </Button>
            <Button onClick={e => createNewGroup(e)} color="primary">
              {t("Create")}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      }   
    </>
  );
}

