import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  formContainer: {
    width: "90%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  form: {
    width: "100%"
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  dividerHorizontal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
    marginBottom:theme.spacing(2),
    width: "100%"
  },
  largeTextField: {
    borderBottomColor: theme.palette.background.light,
    marginBottom:theme.spacing(2),
    width: "100%",
    height:"50"
  },
  selectField: {
    marginBottom:theme.spacing(2),
    marginRight:theme.spacing(2),
    width: "100%",
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formColumnTexts:{
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  media: {
    height: 345,
  },
  mediaRoot: {
    maxWidth: 345,
  },
  linkIconActive: {
    color: theme.palette.primary.main,
  },
  bigAvatar: {
    width: 150,
    height: 150,
  },
  image: {
    margin: theme.spacing(2),
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));
