import React, { useEffect, useState } from "react";
import {
  Grid, Fade, CircularProgress, Typography, IconButton, Tooltip,
} from "@material-ui/core";
import { Edit as EditIcon, FileCopy as CopyIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import MUIDataTable from "mui-datatables";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";


export default function ProgramManagement(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // local
  const [datatableData, setDatatableData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  const columns = [
    {
      label:t('Institue'),
      name: "institue_name"
    },
    {
      label:t('Program Year'),
      name: "program_year"
    },
    {
      label:t('Program Title'),
      name: "program_title"
    },
    {
      label:t('Dates'),
      name: "program_dates"
    },
    {
      name: "",
      options: {
        filter:false,sort:false,print:false,download:false,viewColumns:false,
        customBodyRender: (value, tableMeta, updateValue) => (
          [<Tooltip key={1} title={t("Edit")}>
            <IconButton onClick={e => editText(e,value)} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip key={2} title={t("Delete")}>
            <IconButton onClick={e => deleteText(e,value)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Tooltip>]
        )
      }
    }
  ];


  async function fetchData(){

    let response = await genericFetch(config.apiURL + '/records/programs?join=institues&include=programs.*,institues.institue_name', {...config.fetchConfig}, 
      null, setIsLoading, setErr, setMsg);

    let resultArray = [];
    for (let i = 0; i < response.records.length; i++) {
      resultArray.push([
        response.records[i].institue_id.institue_name,
        response.records[i].program_year,
        response.records[i].program_title,
        response.records[i].program_dates,
        response.records[i]._id
      ]);
    }
    setDatatableData(resultArray);  

    return response;
  }

  async function deleteData(_id){   
    let url = '/records/programs/'+ _id;    
    let response = await genericFetch(config.apiURL + url, {...config.deleteConfig}, 
      t("Program successfully deleted!"), setIsLoading, setErr, setMsg);
    return response;
  }
  
  const editText = (e, _id) => {
    e.preventDefault();
    props.history.push("/app/institueAdmin/programs/edit/"+ _id);
  }

  const deleteText = (e, _id) => {
    e.preventDefault();
    if (window.confirm(t('Are you sure you wish to delete this?'))){
      deleteData(_id)     
    }
  }

  useEffect(() => {
      fetchData();
  }, []);

  return (
    <>
      <PageTitle title={t('Program Management')} button={{title:t('New Program'), link:"#/app/institueAdmin/programs/edit/-1"}} />
      <Grid item xs={12}>
        {isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : (
          <MUIDataTable
            title={t('Program List')}
            data={datatableData}
            columns={columns}
            options={{
              filterType: "dropdown",
              selectableRows:"none",
              responsive:"scroll"
            }}
          />
          )}
      </Grid>

      {(err || msg) && <Toaster err={err} msg={msg}/> }  
    </>
  );
}

