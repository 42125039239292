import React, { useEffect, useState } from "react";
import {
  Grid, TextField, CircularProgress, Button, Fade
} from "@material-ui/core";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";


export default function InstitueEdit(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // local
  const [institueData, setInstitueData] = useState({"institue_name":"","institue_desc":""});

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  async function fetchData(){
    const { id } = props.match.params;

    id>0 && genericFetch(config.apiURL + '/records/institues/'+id, {...config.fetchConfig }, null, setIsLoading, setErr, setMsg)
      .then(data=>setInstitueData(data));

  }

  async function saveData(institueData){   
    let url,fetchConfig;
    if(institueData._id === undefined){
      url = '/records/institues';
      fetchConfig = config.saveConfig;
    }else{
      url = '/records/institues/'+institueData._id; 
      fetchConfig = config.updateConfig;   
    }

    let response = await genericFetch(config.apiURL + url, {...fetchConfig, body:JSON.stringify(institueData) }, 
        t("Institue successfully saved!"), setIsLoading, setErr, setMsg)

    return response;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();  
    saveData(institueData);
  }

  const handleChange = (e) => {
    let obj = { ...institueData, [e.target.name]:e.target.value};
    setInstitueData(obj);
  }

  const goBack = (e) => {
    e.preventDefault();  
    props.history.goBack();
  }


  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg}/> }  
      <PageTitle title={t("Institue Management")} />     
      <Grid item xs={9} md={9}>
        <Widget title={t("Institue Info" )} disableWidgetMenu>
          <div className={classes.formContainer}>
            <div className={classes.form}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <>
                <form className={classes.root} noValidate autoComplete="off" onSubmit={e => handleSubmit(e)}>
                  
                  <div>
                    <TextField name="institue_name" label={t("Institue Name")} type="text" className={classes.textField} 
                                value={institueData.institue_name}
                                onChange={e => handleChange(e)}/>
                  </div>
                  
                  <div>
                    <TextField name="institue_desc" label={t("Description")} type="text" className={classes.textField} 
                              value={institueData.institue_desc}
                              onChange={e => handleChange(e)}/>
                  </div>
                  <div className={classes.formButtons}>
                    <Button
                      disabled={
                        (institueData &&  institueData.institue_name.length === 0) ? true : false
                      }
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {t("Save")}
                    </Button>
                    <Button
                      color="secondary"
                      size="large"
                      className={classes.cancelButton}
                      onClick={e => goBack(e)}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </>
            )}
            </div>
          </div>
        </Widget>
      </Grid>
    </>
  );
}
