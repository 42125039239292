import React, { useState, useEffect } from "react";
import {
  Grid, LinearProgress, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Select,
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip,
} from "@material-ui/core";
import { Launch as LaunchIcon,  FilterList as FilterListIcon,ExpandMore as ExpandMoreIcon} from "@material-ui/icons";
//import { useTheme } from "@material-ui/styles";
import { useTranslation } from 'react-i18next';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Toaster from "../../components/Toaster/Toaster";

// context
import { useUserState } from "../../context/UserContext";
import { genericFetch } from "../../context/FetchContext";

// config
import config from "../../config";

// helper
import {sortBy,dedupArr} from "../../helper";



export default function AllCourses(props) {
  var classes = useStyles();
  //var theme = useTheme();
  var { t } = useTranslation(); 

  // global
  const {user} = useUserState();

  // local
  const [myGroups, setMyGroups] = useState([]);
  const [myCourses, setMyCourses] = useState([]);
  const [myPrograms, setMyPrograms] = useState([]);
  const [filter, setFilter] = useState({"program_id":"0","group_id":"0","program_date":"0"});

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [msg, setMsg] = useState(null);


  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData(){

    let groupsRec = await genericFetch(config.apiURL + '/records/institue_groups?filter=institue_id,eq,'+user.institue_id._id, {...config.fetchConfig}, 
      null, setIsLoading, setErr, setMsg);
    setMyGroups(groupsRec.records)

    let groupIds = groupsRec.records.map(group => group._id).join(",");

    let coursesRec = await genericFetch(config.apiURL + '/records/courses?filter=institue_group_id,in,'+groupIds+'&join=programs&join=texts,topics&join=users', {...config.fetchConfig}, null, setIsLoading, setErr, setMsg);
    setMyCourses(coursesRec.records);
    setMyPrograms(dedupArr(coursesRec.records.sort(sortBy("course_date")).map(course=>course.program_id)));


    return null;
  }


  const handleFilter = (e) => {
    const obj = { ...filter, [e.target.name]:e.target.value};
    setFilter(obj);
  }

  const openText = (e, id,cid,gid,tid) => {
    e.preventDefault();  
    (user._id == tid._id ) 
    ? props.history.push("/app/teacher/courses/"+id+"/"+cid+"/"+gid)
    : props.history.push("/app/library/texts/"+id);
  }


  return (
    <>
      {(err || msg) && <Toaster err={err} msg={msg} />}
      {isLoading && <LinearProgress />}
      <PageTitle title={t("All Programs")} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget header>
            <div className={classes.formColumnTexts}>
              <Select
                native
                className={classes.selectField}
                value={filter.program_id}
                onChange={e => handleFilter(e)}
                inputProps={{
                  name: 'program_id',
                  id: 'program-select',
                }}
              >
                <option key={'program_option_0'} value="0" >{t("All Programs")}</option>
                {myPrograms.map(program => (
                  <option key={'program_option_' + program._id} value={program._id}>{program.program_title}</option>
                ))}
              </Select>
              <Select
                native
                className={classes.selectField}
                value={filter.group_id}
                onChange={e => handleFilter(e)}
                inputProps={{
                  name: 'group_id',
                  id: 'group-select',
                }}
              >
                <option key={'group_option__0'} value="0" >{t("All Groups")}</option>
                {myGroups.map(group => (
                  <option key={'group_option_' + group._id} value={group._id}>{group.group_name}</option>
                ))}
              </Select>
              <Select
                native
                className={classes.selectField}
                value={filter.program_date}
                onChange={e => handleFilter(e)}
                inputProps={{
                  name: 'program_date',
                  id: 'date-select',
                }}
              >
                <option key={'date_option__0'} value="0" >{t("All Dates")}</option>
                {myPrograms.filter(program => program._id == filter.program_id).map(program => (program.program_dates.split(",").map(pg =>
                  <option key={'date_option_' + pg} value={pg}>{pg}</option>
                )))}
              </Select>
              <FilterListIcon/>
            </div>
          </Widget>
        </Grid>
      </Grid>
      
      {myPrograms.filter(program => (filter.program_id=="0" || program._id == filter.program_id )).map((program,pindex) =>
      <ExpansionPanel key={pindex}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{program.program_title}</Typography>
          <Typography className={classes.secondaryHeading}>{program.program_dates}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {program.program_dates.split(",").filter(pdate => (filter.program_date=="0" || pdate == filter.program_date )).map((programDate,pdindex) =>
          <>
            <Grid key={pdindex} item xs={12} sm={6}>
              <List className={classes.root} subheader={programDate} component="nav">
                {myCourses
                  .filter(course => (filter.group_id=="0" || course.institue_group_id == filter.group_id ) && course.course_date===programDate)
                  .sort(sortBy("start_time"))
                  .map((course,cindex) =>
                  [
                    <ListItem key={"list_item_"+cindex} button alignItems="flex-start" onClick={e => openText(e,course.text_id._id,course._id,course.institue_group_id,course.teacher_id )}>
                      <Grid container alignItems="center" >
                        <Typography className={classes.inline} color="primary">
                          {course.start_time.toString().substring(0,5)+"-"+course.end_time.toString().substring(0,5)}
                        </Typography>  
                        <Divider orientation="vertical" flexItem className={classes.divider}/>
                        <Grid item>
                          <ListItemText
                            primary={myGroups.filter(grp=>grp._id === course.institue_group_id ).map(grp => grp.group_name+" / "+course.text_id.topic_id.topic_name)}
                            secondary={
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="secondary"
                                >
                                  {course.text_id.title}
                                </Typography>                              
                              </>
                            }
                          />
                          <ListItemText secondary={course.teacher_id.name+" "+course.teacher_id.surname} />
                        </Grid>
                      </Grid>
                      <ListItemSecondaryAction>
                        <Tooltip key={2} title={t("Open Course")}>
                          <IconButton onClick={e => openText(e,course.text_id._id,course._id,course.institue_group_id,course.teacher_id )} color="primary">
                            <LaunchIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>,
                    <Divider key={"divider_"+cindex} variant="inset" component="li" />
                  ]
                )}
              </List>
            </Grid>
            <Divider orientation="vertical" flexItem className={classes.divider}/>
          </>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      )}

    </>
  );
}

